import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CreditNote } from '../../declarations/types';
import creditNoteController from '../../functions/CreditNoteController';
import { printCreditNote } from '../../functions/DocumentUtils';
import { DocumentDetails } from './Base/DocumentDetails';
import { WithTranslation } from 'react-i18next';

interface CreditNoteDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface CreditNoteDetailsState {
}

export class CreditNoteDetails extends Component<CreditNoteDetailsProps, CreditNoteDetailsState> {

    constructor(props: CreditNoteDetailsProps) {
        super(props);

        this.state = {
        };

        this.renderSpecifics = this.renderSpecifics.bind(this)
    }

    getOne(id: string): Promise<CreditNote> {
        return creditNoteController.getOne(id)
    }

    update(id: string, entity: CreditNote): Promise<CreditNote> {
        return creditNoteController.update(id, entity)
    }

    close(entity: CreditNote): Promise<CreditNote> {
        return creditNoteController.close(entity)
    }

    delete(id: string): Promise<void> {
        return creditNoteController.delete(id)
    }

    renderSpecifics(entity: CreditNote, getState: () => CreditNote, changeCallback: (entity: CreditNote) => void): JSX.Element {
        return (<></>)
    }

    render() {


        return (
            <DocumentDetails
                {...this.props}
                listPrefix='creditnotes'
                getOne={this.getOne}
                update={this.update}
                close={this.close}
                print={printCreditNote}
                delete={this.delete}
                renderSpecifics={this.renderSpecifics} />
        )
    }

}