import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

import { Address, Bill, Offer, Order, BillingDocument, BillingDocumentValues, DeliveryNote, Cancellation, CreditNote } from "../declarations/types";
import { ControllerBase } from "./ControllerBase";
import { dateFormatter, euroFormatter, numberFormatter } from "./Format";

export function getBillingDocumentPositionSum(document: BillingDocument): number {
    let positionSum = 0;
    for (var i = document.positions.length; i--;) {
        positionSum += document.positions[i].price * document.positions[i].count;
    }
    return positionSum
}

export function getBillingDocumentVat(document: BillingDocument): number {
    let positionSum = getBillingDocumentPositionSum(document)
    positionSum = positionSum + document.additionalCosts.shipping + document.additionalCosts.packaging + document.additionalCosts.advice
    let vat = document.intraCommunitySupply ? 0 : positionSum * (document.VAT / 100)
    return vat
}

export function getBillingDocumentOverallSum(document: BillingDocument): number {
    let positionSum = getBillingDocumentPositionSum(document)
    positionSum = positionSum + document.additionalCosts.shipping + document.additionalCosts.packaging + document.additionalCosts.advice
    let vat = document.intraCommunitySupply ? 0 : positionSum * (document.VAT / 100)
    return positionSum + vat
}

export function getBillingDocumentValues(document: BillingDocument): BillingDocumentValues {
    let PositionSum = getBillingDocumentPositionSum(document)
    PositionSum = PositionSum + document.additionalCosts.shipping + document.additionalCosts.packaging + document.additionalCosts.advice
    let vat = document.intraCommunitySupply ? 0 : PositionSum * (document.VAT / 100)
    let documentValues: BillingDocumentValues = {
        Shipping: document.additionalCosts.shipmentAndPackagingAsOnePosition ? euroFormatter.format(document.additionalCosts.shipping + document.additionalCosts.packaging) : euroFormatter.format(document.additionalCosts.shipping),
        Packaging: document.additionalCosts.shipmentAndPackagingAsOnePosition ? "" : euroFormatter.format(document.additionalCosts.packaging),
        Advice: euroFormatter.format(document.additionalCosts.advice),
        PositionSum: euroFormatter.format(PositionSum),
        VAT: euroFormatter.format(vat),
        OverallSum: euroFormatter.format(PositionSum + vat)
    }
    return documentValues
}

export function getBillingDocumentDate(document: BillingDocument): string {
    return document.closed ? dateFormatter.format(new Date(Date.parse(document.date.toString()))) : ""
}

export function getBillingDocumentDateObj(document: BillingDocument): Date | undefined {
    return document.closed ? new Date(Date.parse(document.date.toString())) : undefined
}

export function getBillDueDate(bill: Bill): string {
    return dateFormatter.format(new Date(Date.parse(bill.dueDate.toString())))
}

export function printOffer(offer: Offer) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, offer.customer.address, offer.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";

    let values = "\n" + offer.referenceNumber + "\n" +
        getBillingDocumentDate(offer);

    pdf = setBillingDocumentMetaData(pdf, offer, "Angebot", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, offer);
    [pdf, currentY] = setBillingDocumentPositionTable(pdf, offer, defaultFontSize, currentY);
    [pdf, currentY] = setSumRows(pdf, offer, currentY);
    [pdf, currentY] = setBottomText(pdf, offer, currentY);
    pdf.setDrawColor(0, 0, 0);
    pdf = setHeaderFooter(pdf, offer)

    pdf.save(offer.referenceNumber + "_" + offer.customer.address.name + ".pdf")
}

export function printOrder(order: Order) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, order.customer.address, order.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";

    let values = "\n" + order.referenceNumber + "\n" +
        getBillingDocumentDate(order);

    pdf = setBillingDocumentMetaData(pdf, order, "Auftrag", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, order);
    [pdf, currentY] = setBillingDocumentPositionTable(pdf, order, defaultFontSize, currentY);
    [pdf, currentY] = setSumRows(pdf, order, currentY);
    [pdf, currentY] = setBottomText(pdf, order, currentY);
    pdf.setDrawColor(0, 0, 0);
    pdf = setHeaderFooter(pdf, order)

    pdf.save(order.referenceNumber + "_" + order.customer.address.name + ".pdf")
}

export function printBill(bill: Bill) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, bill.customer.address, bill.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";
    if (bill.hasDueDate) {
        labels += "\nFälligkeitsdatum:"
    }

    let values = "\n" + bill.referenceNumber + "\n" +
        getBillingDocumentDate(bill);
    if (bill.hasDueDate) {
        values += "\n" + getBillDueDate(bill)
    }

    pdf = setBillingDocumentMetaData(pdf, bill, "Rechnung", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, bill);
    [pdf, currentY] = setBillingDocumentPositionTable(pdf, bill, defaultFontSize, currentY);
    [pdf, currentY] = setSumRows(pdf, bill, currentY);
    [pdf, currentY] = setBottomText(pdf, bill, currentY);
    pdf.setDrawColor(0, 0, 0);
    pdf = setHeaderFooter(pdf, bill)

    pdf.save(bill.referenceNumber + "_" + bill.customer.address.name + ".pdf")
}

export function printDeliveryNote(deliveryNote: DeliveryNote) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, deliveryNote.customer.address, deliveryNote.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";

    let values = "\n" + deliveryNote.referenceNumber + "\n" +
        getBillingDocumentDate(deliveryNote);

    pdf = setBillingDocumentMetaData(pdf, deliveryNote, "Lieferschein", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, deliveryNote);
    [pdf, currentY] = setDeliveryNotePositionTable(pdf, deliveryNote, defaultFontSize, currentY);
    [pdf, currentY] = setBottomText(pdf, deliveryNote, currentY);
    pdf.setDrawColor(0, 0, 0);
    [pdf, currentY] = setSignatureLine(pdf, deliveryNote, currentY);
    pdf = setHeaderFooter(pdf, deliveryNote)

    pdf.save(deliveryNote.referenceNumber + "_" + deliveryNote.customer.address.name + ".pdf")
}

export function printCancellation(cancellation: Cancellation) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, cancellation.customer.address, cancellation.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";

    let values = "\n" + cancellation.referenceNumber + "\n" +
        getBillingDocumentDate(cancellation);

    pdf = setBillingDocumentMetaData(pdf, cancellation, "Storno", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, cancellation);
    [pdf, currentY] = setBillingDocumentPositionTable(pdf, cancellation, defaultFontSize, currentY);
    [pdf, currentY] = setSumRows(pdf, cancellation, currentY);
    [pdf, currentY] = setBottomText(pdf, cancellation, currentY);
    pdf.setDrawColor(0, 0, 0);
    pdf = setHeaderFooter(pdf, cancellation)

    pdf.save(cancellation.referenceNumber + "_" + cancellation.customer.address.name + ".pdf")
}

export function printCreditNote(creditNote: CreditNote) {

    let pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont('', 'normal')

    const defaultFontSize = 12

    let currentY = 0

    pdf = setCustomerAddress(pdf, creditNote.customer.address, creditNote.company.address, defaultFontSize);

    let labels =
        "Nr:\n" +
        "Datum:";

    let values = "\n" + creditNote.referenceNumber + "\n" +
        getBillingDocumentDate(creditNote);

    pdf = setBillingDocumentMetaData(pdf, creditNote, "Gutschrift", labels, values);
    [pdf, currentY] = setPdfTextTop(pdf, creditNote);
    [pdf, currentY] = setBillingDocumentPositionTable(pdf, creditNote, defaultFontSize, currentY);
    [pdf, currentY] = setSumRows(pdf, creditNote, currentY);
    [pdf, currentY] = setBottomText(pdf, creditNote, currentY);
    pdf.setDrawColor(0, 0, 0);
    pdf = setHeaderFooter(pdf, creditNote)

    pdf.save(creditNote.referenceNumber + "_" + creditNote.customer.address.name + ".pdf")
}

function setCustomerAddress(pdf: jsPDF, customer: Address, company: Address, defaultFontSize: number): jsPDF {
    // Set the customer header on the invoice
    const addressSize = 6
    const addressMaxWidth = 70

    let companyX = 27;
    let companyY = 47;
    let companyText = company.name + ", " +
        company.street + " " + company.number + ", " +
        company.zipCode + " " + company.city

    pdf.setFontSize(addressSize);
    [pdf, companyY] = setPdfTextBlock(pdf, addressMaxWidth, companyText, companyX, companyY);
    pdf.setFontSize(defaultFontSize)

    let customerX = 27;
    let customerY = 53;
    let nameSuffix = customer.nameSuffix === "" ? "" : customer.nameSuffix + "\n"
    let customerText = customer.name + "\n" + nameSuffix +
        customer.street + " " + customer.number + "\n" +
        customer.zipCode + " " + customer.city + "\n" +
        customer.additionalLine;

    [pdf, companyY] = setPdfTextBlock(pdf, addressMaxWidth, customerText, customerX, customerY);

    return pdf
}

function setBillingDocumentMetaData(pdf: jsPDF, document: BillingDocument, type: string, labels: string, values: string): jsPDF {
    // Set meta data on the invoice
    let labelWidth = 35;
    let labelY = 53;
    let labelX = 130;
    pdf.setFont('', 'bold');
    [pdf, labelY] = setPdfTextBlock(pdf, labelWidth, type, labelX, labelY);
    pdf.setFont('', 'normal');
    [pdf, labelY] = setPdfTextBlock(pdf, labelWidth, labels, labelX, labelY);

    let valueWidth = 21
    let valueY = 53
    let valueX = 162;
    [pdf, labelY] = setPdfTextBlock(pdf, valueWidth, values, valueX, valueY);
    return pdf
}

/**
 * Sets a text block into the document pdf
 * @param pdf 
 * @param maxWidth 
 * @param text 
 * @param startX 
 * @param startY 
 * @returns 
 */
function setPdfTextBlock(pdf: jsPDF,
    maxWidth: number,
    text: string,
    startX: number,
    startY: number,
    align: "left" | "center" | "right" | "justify" = "left"): [jsPDF, number] {

    let lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor
    let block = pdf.splitTextToSize(text, maxWidth);
    pdf.text(block, startX, startY, {
        align: align
    })
    let endY = startY + block.length * lineHeight

    return [pdf, endY]
}

function setPdfTextTop(pdf: jsPDF, document: BillingDocument): [jsPDF, number] {
    let textY = 100;
    let textX = 27;
    let maxWidth = 156;
    if (document.addedTextTop !== "") {
        return setPdfTextBlock(pdf, maxWidth, document.addedTextTop, textX, textY);
    }
    else {
        return [pdf, textY]
    }
}

function setBillingDocumentPositionTable(pdf: jsPDF, document: BillingDocument, defaultFontSize: number, currentY: number): [jsPDF, number] {

    let finalY = currentY
    let positions = document.positions.map(p => {
        let line = {
            description: p.description,
            count: numberFormatter.format(p.count),
            price: euroFormatter.format(p.price),
            value: euroFormatter.format(p.price * p.count)
        }
        return line
    })

    autoTable(pdf, {
        columns: [
            { header: 'Beschreibung', dataKey: 'description' },
            { header: 'Menge', dataKey: 'count' },
            { header: 'Einzelpreis', dataKey: 'price' },
            { header: 'Gesamtpreis', dataKey: 'value' },
        ],
        columnStyles: {
            count: {
                halign: 'right'
            },
            price: {
                halign: 'right'
            },
            value: {
                halign: 'right'
            }
        },
        body: positions,
        startY: currentY + 5,
        margin: {
            horizontal: 27,
            vertical: 32
        },
        styles: {
            overflow: 'linebreak',
            textColor: [0, 0, 0],
            fontSize: 10
        },
        headStyles: {
            fontSize: defaultFontSize,
            fillColor: ControllerBase.currentCompany?.documentAccentColor,
            textColor: ControllerBase.currentCompany?.documentAccentFontColor
        },
        showHead: "everyPage",
        didDrawPage: (data) => {
            if (data.cursor !== null) {
                finalY = data.cursor.y
            }
        }
    })
    return [pdf, finalY]
}

function setDeliveryNotePositionTable(pdf: jsPDF, document: BillingDocument, defaultFontSize: number, currentY: number): [jsPDF, number] {

    let finalY = currentY
    let positions = document.positions.map(p => {
        let line = {
            description: p.description,
            count: numberFormatter.format(p.count)
        }
        return line
    })

    autoTable(pdf, {
        columns: [
            { header: 'Beschreibung', dataKey: 'description' },
            { header: 'Menge', dataKey: 'count' },
        ],
        columnStyles: {
            count: {
                halign: 'right'
            }
        },
        body: positions,
        startY: currentY + 5,
        margin: {
            horizontal: 27,
            vertical: 32
        },
        styles: {
            overflow: 'linebreak',
            textColor: [0, 0, 0],
            fontSize: 10
        },
        headStyles: {
            fontSize: defaultFontSize,
            fillColor: ControllerBase.currentCompany?.documentAccentColor,
            textColor: ControllerBase.currentCompany?.documentAccentFontColor
        },
        showHead: "everyPage",
        didDrawPage: (data) => {
            if (data.cursor !== null) {
                finalY = data.cursor.y
            }
        }
    })
    return [pdf, finalY]
}

function setSumRows(pdf: jsPDF, document: BillingDocument, currentY: number): [jsPDF, number] {

    // Align the start x position with the table values
    let startX = 29
    let startY = currentY + 10
    let labelMaxWidth = 156
    let textMaxWidth = 100

    //Align the right aligned numbers with table values
    let endX = 181

    let documentValues = getBillingDocumentValues(document)
    let hasPackageLine = document.additionalCosts.packaging !== 0;
    let hasShippingLine = document.additionalCosts.shipping !== 0;
    let hasAdviceLine = document.additionalCosts.advice !== 0;

    let sumRowLabels = ""
    if (document.additionalCosts.shipmentAndPackagingAsOnePosition) {
        if (hasPackageLine || hasShippingLine) {
            sumRowLabels += "Versand und Verpackung:\n"
        }
    }
    else {
        if (hasPackageLine) {
            sumRowLabels += "Verpackung:\n"
        }
        if (hasShippingLine) {
            sumRowLabels += "Versand:\n"
        }
    }

    if (hasAdviceLine) {
        sumRowLabels += "Avise:\n"
    }

    //If there are any additional cost we add an empty line
    if (hasPackageLine || hasShippingLine || hasAdviceLine) {
        sumRowLabels += "\n"
    }

    sumRowLabels += "Zwischensumme:\n"

    if (document.intraCommunitySupply) {
        sumRowLabels += "Mehrwertsteuerfrei wegen Innergemeinschaftlicher Lieferung nach §4Nr1b Ustg.\nIhre USt-IdNr\n"
    }
    else {
        sumRowLabels += "Mehrwertsteuer (" + document.VAT + "%):\n"
    }

    let labels = pdf.splitTextToSize(sumRowLabels, labelMaxWidth);

    let lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor
    let intY = startY + labels.length * lineHeight

    let sumLabelText = "Gesamtsumme:"
    pdf.setFont('', 'bold')
    let sumLabel = pdf.splitTextToSize(sumLabelText, labelMaxWidth);
    pdf.setFont('', 'normal')

    let endY = intY + sumLabel.length * lineHeight

    //If block is to big add a new page
    if (endY > 269) {
        pdf.addPage()
        startY = 30
        intY = startY + labels.length * lineHeight
        endY = intY + sumLabel.length * lineHeight
    }
    else {
        let line = startY - 5
        pdf.line(27, line, 183, line + 1)
    }

    let sumRowValues = ""
    if (document.additionalCosts.shipmentAndPackagingAsOnePosition) {
        if (hasPackageLine || hasShippingLine) {
            sumRowValues += documentValues.Shipping + "\n"
        }
    }
    else {
        if (hasPackageLine) {
            sumRowValues += documentValues.Packaging + "\n"
        }
        if (hasShippingLine) {
            sumRowValues += documentValues.Shipping + "\n"
        }
    }

    if (hasAdviceLine) {
        sumRowValues += documentValues.Advice + "\n"
    }

    //If there are any additional cost we add an empty line
    if (hasPackageLine || hasShippingLine || hasAdviceLine) {
        sumRowValues += "\n"
    }

    sumRowValues += documentValues.PositionSum + "\n"

    if (document.intraCommunitySupply) {
        sumRowValues += "\n" + document.customer.VAT_ID + "\n"
    }
    else {
        sumRowValues += documentValues.VAT
    }

    let final = pdf.splitTextToSize(sumRowValues, textMaxWidth);

    pdf.text(labels, startX, startY)
    pdf.text(final, endX, startY, {
        align: 'right'
    })

    //Set bold sum
    pdf.setFont('', 'bold')
    pdf.text(sumLabel, startX, intY)

    let sum = pdf.splitTextToSize(documentValues.OverallSum, textMaxWidth);
    pdf.text(sum, endX, intY, {
        align: 'right'
    })
    pdf.setFont('', 'normal')

    return [pdf, endY]
}

function setBottomText(pdf: jsPDF, document: BillingDocument, startY: number): [jsPDF, number] {

    startY = startY + 10

    let startX = 27;
    let maxWidth = 156;

    let lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor
    let text = pdf.splitTextToSize(document.addedTextBottom, maxWidth);
    let endY = startY + text.length * lineHeight

    //If block is to big add a new page
    if (endY > 269) {
        pdf.addPage()
        startY = 30
        endY = startY + text.length * lineHeight
    }

    pdf.text(text, startX, startY)

    return [pdf, endY]
}

function setSignatureLine(pdf: jsPDF, document: BillingDocument, startY: number): [jsPDF, number] {

    startY = startY + 10

    let startX = 27;
    let maxWidth = 156;

    let lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor
    let text = pdf.splitTextToSize("Ware in Empfang genommen:\n\n\nKennzeichen, Datum, Unterschrift", maxWidth);
    let endY = startY + text.length * lineHeight

    let line = endY - lineHeight - 5
    pdf.line(27, line, 183, line + 1)

    //If block is to big add a new page
    if (endY > 269) {
        pdf.addPage()
        startY = 30
        endY = startY + text.length * lineHeight
    }

    pdf.text(text, startX, startY)

    return [pdf, endY]
}

function setHeaderFooter(pdf: jsPDF, document: BillingDocument): jsPDF {
    //Set header and footer 
    let pdf_pages = pdf.internal.pages
    for (let i = 1; i < pdf_pages.length; i++) {
        // We are telling our pdfObject that we are now working on this page
        pdf.setPage(i)

        let textSize = 8

        // Set header
        let headerMaxWidth = 50
        let companyInfoText = document.company.address.name + "\n" +
            document.company.address.street + " " + document.company.address.number + "\n" +
            document.company.address.zipCode + " " + document.company.address.city + "\n" +
            document.customer.address.additionalLine
        let companyInfoHeader = pdf.setFontSize(textSize)
            .splitTextToSize(companyInfoText, headerMaxWidth);
        pdf.text(companyInfoHeader, 27, 15)
        let companyContactText =
            "Fon: " + document.company.phone + "\n" +
            "Fax: " + document.company.fax + "\n" +
            "Mobile: " + document.company.mobile
        let companyContactHeader = pdf.setFontSize(textSize)
            .splitTextToSize(companyContactText, headerMaxWidth);
        pdf.text(companyContactHeader, 90, 15)

        let companyContactText_2 =
            "E-Mail: " + document.company.mail
        let companyContactHeader_2 = pdf.setFontSize(textSize)
            .splitTextToSize(companyContactText_2, headerMaxWidth);
        pdf.text(companyContactHeader_2, 183, 15, {
            align: "right"
        })

        pdf.line(27, 25, 183, 26)

        //Set footer
        let bankInfoText = "Bankverbindung:\n" +
            "Bank Institut: " + document.company.account.creditInstitute + "\n" +
            "IBAN: " + document.company.account.IBAN + "\n" +
            "BIC: " + document.company.account.BIC
        let bankInfoFooter = pdf.setFontSize(textSize)
            .splitTextToSize(bankInfoText, headerMaxWidth);
        pdf.text(bankInfoFooter, 27, 273)

        let text_right = "USt-IdNr: " + document.company.VAT_ID + "\n\n\nSeite " + i + " von " + (pdf_pages.length - 1)
        let vatIdFooter = pdf.setFontSize(textSize)
            .splitTextToSize(text_right, headerMaxWidth);
        pdf.text(vatIdFooter, 181, 273, {
            align: 'right'
        })

        pdf.line(27, 269, 183, 270)
    }
    return pdf
}
