import { Bill, Offer, Order } from "../declarations/types";
import { DocumentControllerBase } from "./DocumentControllerBase";

class OrderController extends DocumentControllerBase<Order> {

    fromBill(entity: Bill): Promise<Order> {
        return this.postWithToken(this.getRoute("fromBill"), entity)
    }

    fromOffer(entity: Offer): Promise<Order> {
        return this.postWithToken(this.getRoute("fromOffer"), entity)
    }

}

const orderController = new OrderController('order');
export default orderController;