import React, { Component } from 'react';
import { Bill } from '../../declarations/types';
import { euroFormatter } from '../../functions/Format';
import sellerController from '../../functions/CompanyController';
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { WithTranslation } from 'react-i18next';
import "./Dashboard.css";
import { RouteComponentProps } from 'react-router';
import billController from '../../functions/BillController';
import { getBillingDocumentDate, getBillingDocumentDateObj, getBillingDocumentOverallSum, getBillingDocumentPositionSum, getBillingDocumentValues, getBillingDocumentVat } from '../../functions/DocumentUtils';
import { Link } from 'react-router-dom';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

interface IDashboardProps extends RouteComponentProps<any>, WithTranslation {
}

interface IDashboardState {
    years: number[],
    selectedYear: number,
    companyName: string,
    bills: Bill[]
}

export class Dashboard extends Component<IDashboardProps, IDashboardState> {

    constructor(props: IDashboardProps) {
        super(props);

        this.state = {
            years: [],
            selectedYear: 0,
            companyName: "My Company...",
            bills: []
        };

        this.loadCompanyData = this.loadCompanyData.bind(this)
        this.loadBills = this.loadBills.bind(this)
        this.selectedYearChanged = this.selectedYearChanged.bind(this)
    }

    componentDidMount() {
        this.loadCompanyData();
        this.loadBills()
    }

    loadCompanyData() {
        sellerController.getAll()
            .then(data => {
                if (data.length >= 1) {
                    this.setState({ companyName: data[0].address.name })
                }
            })
            .catch(err => console.log(err));
    }

    //TODO: Add logic for loading bills and set stats
    loadBills() {
        let yearSet: Set<number> = new Set()
        billController.getAll()
            .then(data => {
                let bills = data.filter(b => getBillingDocumentDateObj(b) !== undefined)
                bills = bills.filter(b => b.cancellationId === "")
                bills.forEach(b => yearSet.add(getBillingDocumentDateObj(b)!.getFullYear()))
                let years = Array.from(yearSet)
                let selectedYear = Math.max(...years)
                this.setState({ bills, years, selectedYear })
            })
            .catch(err => console.log(err));
    }

    selectedYearChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedYear = parseInt(e.target.value);
        this.setState({ selectedYear });
    }

    render() {

        let bills = this.state.bills.filter(b => getBillingDocumentDateObj(b)!.getFullYear() === this.state.selectedYear)
        let overallSum = bills.reduce((sum, bill) => sum + getBillingDocumentOverallSum(bill), 0);
        let positionSum = bills.reduce((sum, bill) => sum + getBillingDocumentPositionSum(bill), 0);
        let vatSum = bills.reduce((sum, bill) => sum + getBillingDocumentVat(bill), 0);
        let data = months.map(m => {
            return {
                name: m,
                sales_value: bills.filter(b => (getBillingDocumentDateObj(b)!.getMonth() + 1) === m)
                    .reduce((sum, bill) => sum + getBillingDocumentOverallSum(bill), 0),
                open_bills_value: bills.filter(b => (getBillingDocumentDateObj(b)!.getMonth() + 1) === m && !b.payed)
                    .reduce((sum, bill) => sum + getBillingDocumentOverallSum(bill), 0)
            }
        })

        return (
            <div id="Dashboard">

                <fieldset id="Statistics">
                    <legend>{this.props.t('dashboard.statistics')}</legend>

                    <select id="year-selection" value={this.state.selectedYear} onChange={this.selectedYearChanged}>
                        {this.state.years.map(y => <option value={y} key={y}>{y}</option>)}
                    </select>

                    <div id="numbers">
                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('dashboard.sales_value')}:</label>
                            <label className='meta-data-input'>{euroFormatter.format(overallSum)}</label>
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('dashboard.sales_value_clean')}:</label>
                            <label className='meta-data-input'>{euroFormatter.format(positionSum)}</label>
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.vat')}:</label>
                            <label className='meta-data-input'>{euroFormatter.format(vatSum)}</label>
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.shipping_and_packaging')}:</label>
                            <label className='meta-data-input'>{euroFormatter.format(overallSum - vatSum - positionSum)}</label>
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('dashboard.bill_count')}:</label>
                            <label className='meta-data-input'>{bills.length}</label>
                        </div>
                    </div>

                    <div id="chart">
                        <LineChart width={500} height={300} data={data} margin={{
                            top: 5,
                            right: 20,
                            left: 5,
                            bottom: 5,
                        }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"/>
                            <YAxis tickFormatter={x => euroFormatter.format(x)} width={120} />
                            <Tooltip formatter={(value: any, name: any, props: any) => euroFormatter.format(value)} />
                            <Legend />
                            <Line type="monotone" dataKey="sales_value" name={this.props.t('dashboard.sales_value')} stroke="#27AE60" />
                            <Line type="monotone" dataKey="open_bills_value" name={this.props.t('dashboard.open_bills_value')} stroke="#F70606" />
                        </LineChart>
                    </div>

                </fieldset >


                <fieldset id="Open-Bills">
                    <legend>{this.props.t('dashboard.open_bills')}</legend>
                    {this.state.bills.filter(b => b.closed && !b.payed).map(c => {
                        let values = getBillingDocumentValues(c)
                        return (
                            <div key={c.id} className='bill-data'>
                                <Link className="bill-number"
                                    to={{
                                        pathname: "/bill",
                                        search: "id=" + c.id,
                                    }}>
                                    {c.referenceNumber}
                                </Link>
                                <label className={'bill-name'}>{c.customer.address.name}</label>
                                <label className={'bill-value'}>{values.OverallSum}</label>
                                <label className={'bill-date'}>{getBillingDocumentDate(c)}</label>
                            </div>
                        )
                    })}
                </fieldset >
            </div >
        )
    }

}