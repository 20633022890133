import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Customer, defaultCustomer } from '../../declarations/types';
import customerController from '../../functions/CustomerController';
import { DeleteButton } from '../Common/Buttons/DeleteButton';
import { SaveButton } from '../Common/Buttons/SaveButton';
import { WithTranslation } from 'react-i18next';

import "./CustomerDetails.css";

interface ICustomerDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface ICustomerDetailsState {
    data: Customer,
}

export class CustomerDetails extends Component<ICustomerDetailsProps, ICustomerDetailsState> {

    constructor(props: ICustomerDetailsProps) {
        super(props);

        this.state = {
            data: defaultCustomer,
        };

        this.loadData = this.loadData.bind(this);
        this.save = this.save.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.nameSuffixChanged = this.nameSuffixChanged.bind(this);
        this.streetChanged = this.streetChanged.bind(this);
        this.numberChanged = this.numberChanged.bind(this);
        this.zipCodeChanged = this.zipCodeChanged.bind(this);
        this.cityChanged = this.cityChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        this.mobileChanged = this.mobileChanged.bind(this);
        this.faxChanged = this.faxChanged.bind(this);
        this.mailChanged = this.mailChanged.bind(this);
        this.vatIDChanged = this.vatIDChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let newId = this.getQueryId(this.props);
        if (newId !== undefined) {
            this.loadData(newId);
        }
    }

    componentDidUpdate(prevProps: ICustomerDetailsProps, prevState: ICustomerDetailsState) {
        let oldId = this.getQueryId(prevProps);
        let newId = this.getQueryId(this.props);
        if (newId !== undefined && newId !== oldId) {
            this.loadData(newId);
        }
    }

    getQueryId(props: ICustomerDetailsProps): string | undefined {
        const searchParams = new URLSearchParams(props.location.search);
        return searchParams.get("id") || undefined;
    }

    loadData(id: string) {
        customerController.getOne(id)
            .then(data => this.setState({ data }))
            .catch(err => console.log(err));
    }

    save() {
        if (this.state.data.id !== null) {
            customerController.update(this.state.data.id, this.state.data)
                .then(data => this.setState({ data }))
                .catch(err => console.error(err))
        }
        else {
            alert(this.props.t('customer_details.no_id_warning'));
        }
    }

    deleteCustomer() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(this.props.t('customer_details.delete_question'))) {
            if (this.state.data.id !== null) {
                customerController.delete(this.state.data.id)
                    .then(() => {
                        this.props.history.replace({
                            pathname: "/customers",
                            search: '',
                        })
                    })
                    .catch(err => console.error(err))
            }
            else {
                alert(this.props.t('customer_details.no_id_warning'));
            }
        }
    }

    nameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.address.name = e.target.value;
        this.setState({
            data: newData,
        });
    }

    nameSuffixChanged(e: React.ChangeEvent<HTMLInputElement>){
        let newData = this.state.data;
        newData.address.nameSuffix = e.target.value;
        this.setState({
            data: newData,
        });       
    }

    streetChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.address.street = e.target.value;
        this.setState({
            data: newData,
        });
    }

    numberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.address.number = e.target.value;
        this.setState({
            data: newData,
        });
    }

    zipCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.address.zipCode = e.target.value;
        this.setState({
            data: newData,
        });
    }

    cityChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.address.city = e.target.value;
        this.setState({
            data: newData,
        });
    }

    phoneChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.phone = e.target.value;
        this.setState({
            data: newData,
        });
    }

    mobileChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.mobile = e.target.value;
        this.setState({
            data: newData,
        });
    }

    faxChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.fax = e.target.value;
        this.setState({
            data: newData,
        });
    }

    mailChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.mail = e.target.value;
        this.setState({
            data: newData,
        });
    }

    vatIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.VAT_ID = e.target.value;
        this.setState({
            data: newData,
        });
    }

    submit(e: React.FormEvent) {
        e.preventDefault();
        this.save();
    }

    render() {

        return (
            <>
                <div id="Customer-Details" >
                    <form className="meta-data-form" onSubmit={this.submit}>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.name')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.name} onChange={this.nameChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.name_suffix')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.nameSuffix} onChange={this.nameSuffixChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.street')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.street} onChange={this.streetChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.number')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.number} onChange={this.numberChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.zip_code')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.zipCode} onChange={this.zipCodeChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.city')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.address.city} onChange={this.cityChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.phone')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.phone} onChange={this.phoneChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.mobile')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.mobile} onChange={this.mobileChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.fax')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.fax} onChange={this.faxChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.mail')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.mail} onChange={this.mailChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.vat_id')}:</label>
                            <input type="text" className='meta-data-input'
                                value={this.state.data.VAT_ID} onChange={this.vatIDChanged} />
                        </div>
                    </form>
                </div >
                <div className="tool-bar">
                    <SaveButton id="save-button" type="submit" onClick={this.save} />
                    <DeleteButton id="delete-button" onClick={this.deleteCustomer} />
                </div>
            </>
        )
    }
}