import { AuthenticationResult } from "@azure/msal-browser";
import { Session } from "../declarations/types";
import { ControllerBase } from "./ControllerBase";

class SessionController extends ControllerBase {

    /**
     * Sent an authentication request to the backend
     * @param loginKey - The key required to log into the billing tool
     */
    public authenticate(username: string, loginKey: string): Promise<Session> {
        let route = 'api/' + ControllerBase.apiVersion + '/login';
        return new Promise<Session>(function (resolve, reject) {
            fetch(route, {
                method: 'Post',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    Username: username,
                    Key: loginKey
                })
            }).then(response => {
                if (!response.ok) {
                    response.json().then(err => {
                        reject(err);
                    })
                }
                else {
                    response.json().then(authResult => {
                        resolve(authResult);
                    })
                }
            })
                .catch(err => reject(err))
        })
    }

    /**
     * Sent an authentication request to the backend with the result of the ms authentication flow
     * @param result - The result from the ms authentication flow
     */
    public authenticateMS(username: string, result: AuthenticationResult): Promise<Session> {
        let route = 'api/' + ControllerBase.apiVersion + '/login/ms';
        return new Promise<Session>(function (resolve, reject) {
            fetch(route, {
                method: 'Post',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    Username: username,
                    Key: result.idToken
                })
            }).then(response => {
                if (!response.ok) {
                    response.json().then(err => {
                        reject(err);
                    })
                }
                else {
                    response.json().then(authResult => {
                        resolve(authResult);
                    })
                }
            })
                .catch(err => reject(err))
        })
    }

    /**
     * Sent a authentication request to the backend which will only work, if a cookie exists with the session id
     * and for this user a valid token is stored in the backend
     */
    public silentAuthenticate(): Promise<Session> {
        let route = 'api/' + ControllerBase.apiVersion + '/login';
        return new Promise<Session>(function (resolve, reject) {
            fetch(route, {
                method: 'Get',
                headers: {
                    'content-type': 'application/json'
                }
            }).then(response => {
                if (!response.ok) {
                    response.json().then(err => {
                        reject(err);
                    })
                }
                else {
                    response.json().then(authResult => {
                        resolve(authResult);
                    })
                }
            })
                .catch(err => reject(err));
        })
    }

    /**
     * Ends the current session and forget the seesion id
     */
    public quit(): Promise<any> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        };
        let route = 'api/' + ControllerBase.apiVersion + '/login/logout';
        return new Promise<any>(function (resolve, reject) {
            fetch(route, {
                method: 'Post',
                headers: requestHeaders
            }).then(response => {
                window.sessionStorage.removeItem('session');
                resolve(undefined);
            })
                .catch(err => reject(err))
        })
    }
}

const sessionController = new SessionController();
export default sessionController;