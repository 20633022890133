export const msalConfig = {
    auth: {
      clientId: "56f179ee-c9fa-460b-b634-a11a3b4ce649",
      authority: "https://login.microsoftonline.com/consumers",
      redirectUri: "https://rechnungen.vonohlenlandtechnik.de/spa-auth",
      //redirectUri: "https://localhost:5001/spa-auth",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };