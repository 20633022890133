/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { WithTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./DocumentDetails.css";
import { DocumentPositionComponent } from './DocumentPositionComponent';
import { TabControl } from '../../Common/TabControl';
import customerController from '../../../functions/CustomerController';
import { SearchInput } from '../../Common/Search/SearchInput';
import articleController from '../../../functions/ArticleController';
import { SaveButton } from '../../Common/Buttons/SaveButton';
import { Article, BillingDocument, BillingDocumentPosition, Customer, defaultBillingDocumentPosition, SearchElement } from '../../../declarations/types';
import { getBillingDocumentValues } from '../../../functions/DocumentUtils';
import { AddButton } from '../../Common/Buttons/AddButton';
import { CloseButton } from '../../Common/Buttons/CloseButton';
import { PrintButton } from '../../Common/Buttons/PrintButton';
import { DeleteButton } from '../../Common/Buttons/DeleteButton';
import { dateFormatter } from '../../../functions/Format';

interface IDocumentDetailsProps<T extends BillingDocument> extends RouteComponentProps<any>, WithTranslation {
    listPrefix: string,
    getOne: (id: string) => Promise<T>,
    update: (id: string, entity: T) => Promise<T>,
    close: (entity: T) => Promise<T>,
    print: (entity: T) => void,
    delete: (id: string) => Promise<void>,
    renderSpecifics: (entity: T, getState: () => T, changeCallback: (entity: T) => void) => JSX.Element,
}

interface IDocumentDetailsState<T extends BillingDocument> {
    customers: SearchElement<Customer>[],
    articles: SearchElement<Article>[],
    hasChanges: boolean,
    data: T | null,
}

export class DocumentDetails<T extends BillingDocument> extends Component<IDocumentDetailsProps<T>, IDocumentDetailsState<T>> {

    constructor(props: IDocumentDetailsProps<T>) {
        super(props);

        this.state = {
            data: null,
            hasChanges: false,
            customers: [],
            articles: []
        };

        this.loadData = this.loadData.bind(this);
        this.loadCustomerList = this.loadCustomerList.bind(this);
        this.save = this.save.bind(this);
        this.closeDocument = this.closeDocument.bind(this);
        this.printDocument = this.printDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.setNewDocument = this.setNewDocument.bind(this);
        this.documentChanged = this.documentChanged.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.nameSuffixChanged = this.nameSuffixChanged.bind(this);
        this.streetChanged = this.streetChanged.bind(this);
        this.numberChanged = this.numberChanged.bind(this);
        this.zipCodeChanged = this.zipCodeChanged.bind(this);
        this.cityChanged = this.cityChanged.bind(this);
        this.deliverynameChanged = this.deliverynameChanged.bind(this);
        this.deliverynameSuffixChanged = this.deliverynameSuffixChanged.bind(this);
        this.deliverystreetChanged = this.deliverystreetChanged.bind(this);
        this.deliverynumberChanged = this.deliverynumberChanged.bind(this);
        this.deliveryzipCodeChanged = this.deliveryzipCodeChanged.bind(this);
        this.deliverycityChanged = this.deliverycityChanged.bind(this);
        this.vatChanged = this.vatChanged.bind(this);
        this.intraCommunitySupplyChanged = this.intraCommunitySupplyChanged.bind(this);
        this.shipmentAndPackagingAsOnePositionChanged = this.shipmentAndPackagingAsOnePositionChanged.bind(this);
        this.differentDeliveryAddressChanged = this.differentDeliveryAddressChanged.bind(this);
        this.customerVATIDChanged = this.customerVATIDChanged.bind(this);
        this.packagingChanged = this.packagingChanged.bind(this);
        this.shippingChanged = this.shippingChanged.bind(this);
        this.adviceChanged = this.adviceChanged.bind(this);
        this.addPosition = this.addPosition.bind(this);
        this.companynameChanged = this.companynameChanged.bind(this);
        this.companystreetChanged = this.companystreetChanged.bind(this);
        this.companynumberChanged = this.companynumberChanged.bind(this);
        this.companyzipCodeChanged = this.companyzipCodeChanged.bind(this);
        this.companycityChanged = this.companycityChanged.bind(this);
        this.companyphoneChanged = this.companyphoneChanged.bind(this);
        this.companymobileChanged = this.companymobileChanged.bind(this);
        this.companyfaxChanged = this.companyfaxChanged.bind(this);
        this.companymailChanged = this.companymailChanged.bind(this);
        this.companyvatChanged = this.companyvatChanged.bind(this);
        this.companycreditInstituteChanged = this.companycreditInstituteChanged.bind(this);
        this.companyibanChanged = this.companyibanChanged.bind(this);
        this.companybicChanged = this.companybicChanged.bind(this);
        this.importCustomer = this.importCustomer.bind(this);
        this.importArticle = this.importArticle.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.removePosition = this.removePosition.bind(this);
        this.dateChanged = this.dateChanged.bind(this);
        this.addedTextTopChanged = this.addedTextTopChanged.bind(this);
        this.addedTextBottomChanged = this.addedTextBottomChanged.bind(this);

    }

    componentWillUnmount() {
        if (this.state.hasChanges) {

            if (confirm(this.props.t('documents.base_details.save_question'))) {
                this.save()
            }
        }
    }

    componentDidMount() {
        let newId = this.getQueryId(this.props);
        if (newId !== undefined) {
            this.loadData(newId);
            this.loadCustomerList();
            this.loadArticleList();
        }
    }

    componentDidUpdate(prevProps: IDocumentDetailsProps<T>, prevState: IDocumentDetailsState<T>) {
        let oldId = this.getQueryId(prevProps);
        let newId = this.getQueryId(this.props);
        if (newId !== undefined && newId !== oldId) {
            this.loadData(newId);
        }
    }

    getQueryId(props: IDocumentDetailsProps<T>): string | undefined {
        const searchParams = new URLSearchParams(props.location.search);
        return searchParams.get("id") || undefined;
    }

    loadData(id: string) {
        this.props.getOne(id)
            .then(data => this.setNewDocument(data))
            .catch(err => console.log(err));
    }

    loadCustomerList() {
        customerController.getAll()
            .then(customers => {
                customers = customers.filter(c => c.address.name !== "")
                let searchList: SearchElement<Customer>[] = customers.map(c => {
                    let s = { name: c.address.name, value: c }
                    return s
                })
                this.setState({ customers: searchList })
            })
            .catch(err => console.log(err));
    }

    loadArticleList() {
        articleController.getAll()
            .then(articles => {
                let searchList: SearchElement<Article>[] = articles.map(a => {
                    let s = { name: a.description, value: a }
                    return s
                })
                this.setState({ articles: searchList })
            })
            .catch(err => console.log(err));
    }

    save() {
        this.props.update(this.state.data!.id!, this.state.data!)
            .then(data => this.setNewDocument(data))
            .catch(err => console.error(err))
    }

    closeDocument() {
        if (this.state.data!.closed) {
            alert(this.props.t('documents.base_details.close_error'))
        }
        else {
            if (confirm(this.props.t('documents.base_details.close_question'))) {
                this.props.close(this.state.data!)
                    .then(data => this.setNewDocument(data))
                    .catch(err => console.error(err))
            }
        }
    }

    printDocument() {
        // if (this.state.data!.closed) {
        //     this.props.print(this.state.data!)
        // }
        // else{
        //     alert("Das Dokument kann erst gedruckt werden nachdem es geschlossen wurde!")
        // }
        this.props.print(this.state.data!)
    }

    deleteDocument() {
        if (this.state.data!.closed) {
            alert(this.props.t('documents.base_details.close_error'))
        }
        else {
            if (confirm(this.props.t('documents.base_details.delete_question'))) {
                this.setState({ hasChanges: false })
                this.props.delete(this.state.data!.id!)
                    .then(_ => {
                        this.props.history.push({
                            pathname: this.props.listPrefix
                        });
                    })
                    .catch(err => console.error(err))
            }
        }
    }

    setNewDocument(document: T) {
        this.setState({
            data: document,
            hasChanges: false
        })
    }

    documentChanged(document: T) {
        this.setState({
            data: document,
            hasChanges: true
        });
    }

    nameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.name = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    nameSuffixChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.nameSuffix = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    streetChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.street = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    numberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.number = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    zipCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.zipCode = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    cityChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.address.city = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliverynameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.name = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliverynameSuffixChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.nameSuffix = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliverystreetChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.street = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliverynumberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.number = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliveryzipCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.zipCode = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    deliverycityChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.deliveryAddress.city = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    differentDeliveryAddressChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.differentDeliveryAddress = !this.state.data!.differentDeliveryAddress;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companynameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.address.name = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companystreetChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.address.street = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companynumberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.address.number = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companyzipCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.address.zipCode = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companycityChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.address.city = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companyphoneChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.phone = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companymobileChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.mobile = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companyfaxChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.fax = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companymailChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.mail = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companyvatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.VAT_ID = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companycreditInstituteChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.account.creditInstitute = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companyibanChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.account.IBAN = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    companybicChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.company.account.BIC = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    shippingChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.additionalCosts.shipping = parseFloat(e.target.value);
        if (isNaN(newData!.additionalCosts.shipping)) {
            newData!.additionalCosts.shipping = 0
        }
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    packagingChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.additionalCosts.packaging = parseFloat(e.target.value);
        if (isNaN(newData!.additionalCosts.packaging)) {
            newData!.additionalCosts.packaging = 0
        }
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    adviceChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.additionalCosts.advice = parseFloat(e.target.value);
        if (isNaN(newData!.additionalCosts.advice)) {
            newData!.additionalCosts.advice = 0
        }
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    intraCommunitySupplyChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.intraCommunitySupply = !this.state.data!.intraCommunitySupply;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    shipmentAndPackagingAsOnePositionChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.additionalCosts.shipmentAndPackagingAsOnePosition = !this.state.data!.additionalCosts.shipmentAndPackagingAsOnePosition;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    vatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.VAT = parseFloat(e.target.value);
        if (isNaN(newData!.VAT)) {
            newData!.VAT = 0
        }
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    customerVATIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData!.customer.VAT_ID = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    dateChanged(date: Date) {
        let newData = this.state.data;
        newData!.date = date;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    addedTextTopChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        let newData = this.state.data;
        newData!.addedTextTop = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    addedTextBottomChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        let newData = this.state.data;
        newData!.addedTextBottom = e.target.value;
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    importCustomer(c: SearchElement<Customer> | undefined) {
        if (c !== undefined) {
            let newData = this.state.data;
            newData!.customer = JSON.parse(JSON.stringify(c.value))
            newData!.deliveryAddress = JSON.parse(JSON.stringify(c.value.address))
            this.setState({
                data: newData,
                hasChanges: true
            });
        }
    }

    importArticle(a: SearchElement<Article> | undefined) {
        if (a !== undefined) {
            let newData = this.state.data;
            let clone: BillingDocumentPosition = {
                id: uuid(),
                count: 1,
                description: a.value.description,
                price: a.value.price
            };
            newData!.positions.push(clone);
            this.setState({
                data: newData,
                hasChanges: true
            });
        }
    }

    addPosition() {
        let newData = this.state.data;
        let clone = JSON.parse(JSON.stringify(defaultBillingDocumentPosition));
        clone.id = uuid();
        newData!.positions.push(clone);
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    removePosition(position: BillingDocumentPosition) {
        if (confirm(this.props.t('documents.base_details.delete_position_question'))) {
            let newData = this.state.data;
            let index = newData!.positions.map(p => p.id).indexOf(position.id);
            if (index !== -1) {
                newData!.positions.splice(index, 1)
            }
            this.setState({
                data: newData,
                hasChanges: true
            });
        }
    }

    updatePosition(position: BillingDocumentPosition) {
        let newData = this.state.data;
        newData!.positions = this.state.data!.positions.map(p =>
            p.id === position.id ? { ...position } : p
        )
        this.setState({
            data: newData,
            hasChanges: true
        });
    }

    render() {
        let content = <h1>Loading...</h1>
        if (this.state.data !== null) {
            let documentValues = getBillingDocumentValues(this.state.data);
            let date = this.state.data.closed ? dateFormatter.format(new Date(Date.parse(this.state.data.date.toString()))) : ""
            content = <>
                <div id="Document-Details" >
                    <TabControl tabs={
                        new Map<string, JSX.Element>([
                            [this.props.t('common.overview'),
                            <div className='tab-item'>
                                <fieldset className="meta-data-form">
                                    <legend>{this.props.t('common.receipt')}</legend>
                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.date')}:</label>
                                        <label className='meta-data-input'>{date}</label>
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.id')}:</label>
                                        <label className='meta-data-input'>{this.state.data.referenceNumber}</label>
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.text_top')}:</label>
                                        <textarea placeholder={"..."}
                                            value={this.state.data.addedTextTop} onChange={this.addedTextTopChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.text_bottom')}:</label>
                                        <textarea placeholder={"..."}
                                            value={this.state.data.addedTextBottom} onChange={this.addedTextBottomChanged} />
                                    </div>
                                    {
                                        this.props.renderSpecifics(this.state.data!, () => this.state.data!, this.documentChanged)
                                    }
                                </fieldset>

                                <fieldset className="meta-data-form">
                                    <legend>{this.props.t('common.values')}</legend>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.position_sum')}:</label>
                                        <label className='meta-data-input'>{documentValues.PositionSum}</label>
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.vat')}:</label>
                                        <label className='meta-data-input'>{documentValues.VAT}</label>
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.total_sum')}:</label>
                                        <label className='meta-data-input'>{documentValues.OverallSum}</label>
                                    </div>
                                </fieldset>
                            </div>],
                            [this.props.t('common.buyer'),
                            <div className='tab-item'>
                                <fieldset className="meta-data-form document-options">
                                    <legend>{this.props.t('common.options')}</legend>
                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.different_delivery_address')}:</label>
                                        <input className='meta-data-checkbox' type="checkbox" checked={this.state.data.differentDeliveryAddress} onChange={this.differentDeliveryAddressChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.vat_id')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.VAT_ID ?? ""} onChange={this.customerVATIDChanged} />
                                    </div>

                                    <SearchInput
                                        {...this.props}
                                        id="customer-search"
                                        elements={this.state.customers}
                                        selectElement={this.importCustomer}
                                        selectedElement={undefined} />

                                </fieldset>
                                <fieldset className="meta-data-form">
                                    <legend>{this.props.t('common.address')}</legend>
                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.name')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.name} onChange={this.nameChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.name_suffix')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.nameSuffix} onChange={this.nameSuffixChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.street')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.street} onChange={this.streetChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.number')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.number} onChange={this.numberChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.zip_code')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.zipCode} onChange={this.zipCodeChanged} />
                                    </div>

                                    <div className='meta-data-property'>
                                        <label className='meta-data-label'>{this.props.t('common.city')}:</label>
                                        <input type="text" className='meta-data-input'
                                            value={this.state.data.customer.address.city} onChange={this.cityChanged} />
                                    </div>
                                </fieldset>

                                {this.state.data.differentDeliveryAddress &&
                                    <fieldset className="meta-data-form">
                                        <legend>{this.props.t('common.delivery_address')}</legend>
                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.name')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.name} onChange={this.deliverynameChanged} />
                                        </div>

                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.name_suffix')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.nameSuffix} onChange={this.deliverynameSuffixChanged} />
                                        </div>

                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.street')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.street} onChange={this.deliverystreetChanged} />
                                        </div>

                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.number')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.number} onChange={this.deliverynumberChanged} />
                                        </div>

                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.zip_code')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.zipCode} onChange={this.deliveryzipCodeChanged} />
                                        </div>

                                        <div className='meta-data-property'>
                                            <label className='meta-data-label'>{this.props.t('common.city')}:</label>
                                            <input type="text" className='meta-data-input'
                                                value={this.state.data.deliveryAddress.city} onChange={this.deliverycityChanged} />
                                        </div>
                                    </fieldset>
                                }
                            </div>],
                            [this.props.t('common.seller'),
                            <fieldset className="meta-data-form tab-item">
                                <legend>{this.props.t('common.seller')}</legend>
                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.name')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.address.name} onChange={this.companynameChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.street')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.address.street} onChange={this.companystreetChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.number')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.address.number} onChange={this.companynumberChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.zip_code')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.address.zipCode} onChange={this.companyzipCodeChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.city')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.address.city} onChange={this.companycityChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.phone')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.phone} onChange={this.companyphoneChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.mobile')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.mobile} onChange={this.companymobileChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.fax')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.fax} onChange={this.companyfaxChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.mail')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.mail} onChange={this.companymailChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.vat_id')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.VAT_ID} onChange={this.companyvatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.credit_institute')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.account.creditInstitute} onChange={this.companycreditInstituteChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.iban')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.account.IBAN} onChange={this.companyibanChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.bic')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.data.company.account.BIC} onChange={this.companybicChanged} />
                                </div>
                            </fieldset>],
                            [this.props.t('common.positions'),
                            <div className='tab-item'>
                                <fieldset className="meta-data-form document-options">
                                    <legend>{this.props.t('common.options')}</legend>

                                    <SearchInput
                                        {...this.props}
                                        id="article-search"
                                        elements={this.state.articles}
                                        selectElement={this.importArticle}
                                        selectedElement={undefined} />

                                </fieldset>
                                <fieldset id="document-positions">
                                    <legend>{this.props.t('common.positions')}</legend>
                                    {this.state.data.positions.map(p => {
                                        return (
                                            <DocumentPositionComponent {...this.props} key={p.id} position={p} updatePosition={this.updatePosition} removePosition={this.removePosition} />
                                        )
                                    })}
                                </fieldset>

                                <div id="buttons" className="vertical-button-line">
                                    <AddButton id="add-button" onClick={this.addPosition} />
                                </div>
                            </div>],
                            [this.props.t('common.shipping'),
                            <fieldset className="meta-data-form document-options tab-item">
                                <legend>{this.props.t('common.shipping_and_packaging')}</legend>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.combine_positions')}:</label>
                                    <input className='meta-data-checkbox' type="checkbox" checked={this.state.data.additionalCosts.shipmentAndPackagingAsOnePosition} onChange={this.shipmentAndPackagingAsOnePositionChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.shipping')}:</label>
                                    <input type='number'
                                        step="0.01" className='meta-data-input'
                                        value={this.state.data.additionalCosts.shipping} onChange={this.shippingChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.packaging')}:</label>
                                    <input type='number'
                                        step="0.01" className='meta-data-input'
                                        value={this.state.data.additionalCosts.packaging} onChange={this.packagingChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.advice')}:</label>
                                    <input type='number'
                                        step="0.01" className='meta-data-input'
                                        value={this.state.data.additionalCosts.advice} onChange={this.adviceChanged} />
                                </div>
                            </fieldset>],
                            [this.props.t('common.tax'),
                            <fieldset className="meta-data-form document-options tab-item">
                                <legend>{this.props.t('common.tax')}</legend>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.vat')}:</label>
                                    <input type='number'
                                        step="0.01" className='meta-data-input'
                                        value={this.state.data.VAT} onChange={this.vatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.intraCommunitySupply')}:</label>
                                    <input className='meta-data-checkbox' type="checkbox" checked={this.state.data.intraCommunitySupply} onChange={this.intraCommunitySupplyChanged} />
                                </div>
                            </fieldset>],
                        ])}
                        defaultSelection={this.props.t('common.overview')} />
                </div >
                <div className="tool-bar">
                    <SaveButton id="save-button" onClick={this.save} />
                    <CloseButton id="close-button" onClick={this.closeDocument} />
                    <PrintButton id="print-button" onClick={this.printDocument} />
                    <DeleteButton id="delete-button" onClick={this.deleteDocument} />
                </div>
            </>
        }

        return (content)
    }
}