import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./MsAuthConfig";
import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { WithTranslation } from "react-i18next";

interface IMsSignInButtonProps extends WithTranslation {
    id: string,
    authenticatedCallback: (response: AuthenticationResult) => void,
}

function handleLogin(instance: IPublicClientApplication, authenticatedCallback: (response: AuthenticationResult) => void) {
    instance.loginPopup(loginRequest)
        .then(response => {
            authenticatedCallback(response);
        })
        .catch(e => {
            console.error(e);
        });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const MsSignInButton = (props: IMsSignInButtonProps) => {
    const { instance } = useMsal();

    return (
        <button id={props.id} onClick={() => handleLogin(instance, props.authenticatedCallback)}>{props.t('common.ms_login')}</button>
    );
}