import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Offer } from '../../declarations/types';
import offerController from '../../functions/OfferController';
import { printOffer } from '../../functions/DocumentUtils';
import { DocumentDetails } from './Base/DocumentDetails';
import { AddButton } from '../Common/Buttons/AddButton';
import billController from '../../functions/BillController';
import orderController from '../../functions/OrderController';
import { WithTranslation } from 'react-i18next';

interface OfferDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface OfferDetailsState {
}

export class OfferDetails extends Component<OfferDetailsProps, OfferDetailsState> {

    constructor(props: OfferDetailsProps) {
        super(props);

        this.state = {
        };

        this.renderSpecifics = this.renderSpecifics.bind(this)
    }

    getOne(id: string): Promise<Offer> {
        return offerController.getOne(id)
    }

    update(id: string, entity: Offer): Promise<Offer> {
        return offerController.update(id, entity)
    }

    close(entity: Offer): Promise<Offer> {
        return offerController.close(entity)
    }

    delete(id: string): Promise<void> {
        return offerController.delete(id)
    }

    addBill(offer: Offer) {
        billController.fromOffer(offer)
            .then(c => {
                this.props.history.push({
                    pathname: "bill",
                    search: "id=" + c.id,
                });
            })
    }

    addOrder(offer: Offer) {
        orderController.fromOffer(offer)
            .then(c => {
                this.props.history.push({
                    pathname: "order",
                    search: "id=" + c.id,
                });
            })
    }

    renderSpecifics(entity: Offer, getState: () => Offer, changeCallback: (entity: Offer) => void): JSX.Element {
        let billContent = entity.billId === "" ?
            <AddButton id="add-Bill" onClick={() => this.addBill(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "bill",
                    search: "id=" + entity.billId,
                }}>
                {entity.billId}
            </Link>
        let orderContent = entity.orderId === "" ?
            <AddButton id="add-Order" onClick={() => this.addOrder(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "order",
                    search: "id=" + entity.orderId,
                }}>
                {entity.orderId}
            </Link>

        return (<>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.order')}:</label>
                {orderContent}
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.bill')}:</label>
                {billContent}
            </div>
        </>)
    }

    render() {


        return (
            <DocumentDetails
                {...this.props}
                listPrefix='offers'
                getOne={this.getOne}
                update={this.update}
                close={this.close}
                print={printOffer}
                delete={this.delete}
                renderSpecifics={this.renderSpecifics} />
        )
    }

}