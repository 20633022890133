import { IEntity } from "../declarations/types";
import { ControllerBase } from "./ControllerBase";

export class EntityControllerBase<T extends IEntity> extends ControllerBase {

    //The prefix for the generic routes
    routeModifier: string;

    //Creates a new instance of the base rest api base class
    constructor(routeModifier: string) {
        super();
        this.routeModifier = routeModifier;
    }

    /**
     * Creates a get request for the provided route and attaches the bearer token for the current session
     * @param route The route to the get request
     * @returns An instance or collecton depending on the type parameter
     */
    getWithToken<T>(route: string): Promise<T> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let companyId = ControllerBase.currentCompany?.id;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'billing-companyId': companyId,
        };
        if (token !== null && token !== undefined) {
            return new Promise<T>(function (resolve, reject) {
                fetch(route, {
                    method: 'Get',
                    headers: requestHeaders,
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(err => {
                            reject(err);
                        })
                    }
                    else {
                        response.json().then(entity => {
                            resolve(entity);
                        })
                    }
                })
                    .catch(err => reject(err));
            })
        }
        else {
            return new Promise<T>(function (resolve, reject) { reject("No token declared") })
        }
    }

    /**
     * Creates a post request for the provided route and attaches the bearer token for the current session
     * @param route The route to the post request
     * @param body The object which is attached as the body to the request 
     * @returns An instance or collecton depending on the type parameter
     */
    postWithToken<T, U>(route: string, body: U): Promise<T> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let companyId = ControllerBase.currentCompany?.id;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'billing-companyId': companyId,
        };
        if (token !== null && token !== undefined) {
            return new Promise<T>(function (resolve, reject) {
                fetch(route, {
                    method: 'Post',
                    headers: requestHeaders,
                    body: JSON.stringify(body),
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(err => {
                            reject(err);
                        })
                    }
                    else {
                        response.json().then(entity => {
                            resolve(entity);
                        })
                    }
                })
                    .catch(err => reject(err));
            })
        }
        else {
            return new Promise<T>(function (resolve, reject) { reject("No token declared") })
        }
    }

    /**
     * Creates a post request with an empty body for the provided route and attaches the bearer token for the current session
     * @param route The route to the post request
     * @returns An instance or collecton depending on the type parameter
     */
    postWithTokenNoBody<T>(route: string): Promise<T> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let companyId = ControllerBase.currentCompany?.id;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'billing-companyId': companyId,
        };
        if (token !== null && token !== undefined) {
            return new Promise<T>(function (resolve, reject) {
                fetch(route, {
                    method: 'Post',
                    headers: requestHeaders,
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(err => {
                            reject(err);
                        })
                    }
                    else {
                        response.json().then(entity => {
                            resolve(entity);
                        })
                    }
                })
                    .catch(err => reject(err));
            })
        }
        else {
            return new Promise<T>(function (resolve, reject) { reject("No token declared") })
        }
    }

    /**
     * Creates a put request for the provided route and attaches the bearer token for the current session
     * @param route The route to the put request
     * @param body The object which is attached as the body to the request 
     * @returns An instance or collecton depending on the type parameter
     */
    putWithToken<T, U>(route: string, body: U): Promise<T> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let companyId = ControllerBase.currentCompany?.id;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'billing-companyId': companyId,
        };
        if (token !== null && token !== undefined) {
            return new Promise<T>(function (resolve, reject) {
                fetch(route, {
                    method: 'Put',
                    headers: requestHeaders,
                    body: JSON.stringify(body),
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(err => {
                            reject(err);
                        })
                    }
                    else {
                        response.json().then(entity => {
                            resolve(entity);
                        })
                    }
                })
                    .catch(err => reject(err));
            })
        }
        else {
            return new Promise<T>(function (resolve, reject) { reject("No token declared") })
        }
    }

    /**
     * Creates a delete request for the provided route and attaches the bearer token for the current session
     * @param route The route to the put request
     * @returns An instance or collecton depending on the type parameter
     */
    deleteWithToken(route: string): Promise<void> {
        let token = ControllerBase.currentSession.session?.sessionToken;
        let companyId = ControllerBase.currentCompany?.id;
        let requestHeaders: any = {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'billing-companyId': companyId,
        };
        if (token !== null && token !== undefined) {
            return new Promise<void>(function (resolve, reject) {
                fetch(route, {
                    method: 'Delete',
                    headers: requestHeaders,
                }).then(response => {
                    if (!response.ok) {
                        response.json().then(err => {
                            reject(err);
                        })
                    }
                    else {
                        resolve();
                    }
                })
                    .catch(err => reject(err));
            })
        }
        else {
            return new Promise<void>(function (resolve, reject) { reject("No token declared") })
        }
    }

    /**
     * Get all entities
     */
    getAll(): Promise<T[]> {
        return this.getWithToken(this.getRoute());
    }

    /**
     * Get all entities
     */
    getOne(id: string): Promise<T> {
        return this.getWithToken(this.getRoute(id));
    }

    /**
     * Creates a new entity
     */
    create(entity: T): Promise<T> {
        return this.postWithToken(this.getRoute(), entity);
    }

    /**
     * Updates an existing entity
     */
    update(id: string, entity: T): Promise<T> {
        return this.putWithToken(this.getRoute(id), entity);
    }

    /**
     * Deletes an existing entity
     */
    delete(id: string): Promise<void> {
        return this.deleteWithToken(this.getRoute(id));
    }

    /**
     * Get the route with the base prefix for the controller.
     * Example: hello/world -> api/v1/routeModifier/hello/world
     * @param suffix The suffix that points to the correct method on the controller
     * @returns a string that contains the complete route
     */
    getRoute(suffix: string | undefined = undefined): string {
        if (suffix === undefined) {
            return 'api/' + ControllerBase.apiVersion + '/' + this.routeModifier;
        }
        else {
            return 'api/' + ControllerBase.apiVersion + '/' + this.routeModifier + '/' + suffix;
        }
    }
}