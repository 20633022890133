import { Bill, Offer, Order } from "../declarations/types";
import { DocumentControllerBase } from "./DocumentControllerBase";

class OfferController extends DocumentControllerBase<Offer> {

    fromBill(entity: Bill): Promise<Offer> {
        return this.postWithToken(this.getRoute("fromBill"), entity)
    }

    fromOrder(entity: Order): Promise<Offer> {
        return this.postWithToken(this.getRoute("fromOrder"), entity)
    }

}

const offerController = new OfferController('offer');
export default offerController;