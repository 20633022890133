import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Bill } from '../../declarations/types';
import billController from '../../functions/BillController';
import { printBill } from '../../functions/DocumentUtils';
import { DocumentDetails } from './Base/DocumentDetails';
import DatePicker from "react-datepicker";
import { AddButton } from '../Common/Buttons/AddButton';
import deliveryNoteController from '../../functions/DeliveryNoteController';
import { WithTranslation } from 'react-i18next';
import orderController from '../../functions/OrderController';
import offerController from '../../functions/OfferController';
import cancellationController from '../../functions/CancellationController';

interface BillDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface BillDetailsState {
}

export class BillDetails extends Component<BillDetailsProps, BillDetailsState> {

    constructor(props: BillDetailsProps) {
        super(props);

        this.state = {
        };

        this.renderSpecifics = this.renderSpecifics.bind(this)
    }

    getOne(id: string): Promise<Bill> {
        return billController.getOne(id)
    }

    update(id: string, entity: Bill): Promise<Bill> {
        return billController.update(id, entity)
    }

    close(entity: Bill): Promise<Bill> {
        return billController.close(entity)
    }

    delete(id: string): Promise<void> {
        return billController.delete(id)
    }


    hasDueDateChanged(e: React.ChangeEvent<HTMLInputElement>, getState: () => Bill, changeCallback: (entity: Bill) => void) {
        let newData = getState();
        newData.hasDueDate = !newData.hasDueDate;
        changeCallback(newData)
    }

    payedChanged(e: React.ChangeEvent<HTMLInputElement>, getState: () => Bill, changeCallback: (entity: Bill) => void) {
        let newData = getState();
        newData.payed = !newData.payed;
        changeCallback(newData)
    }

    dateChanged(date: Date, getState: () => Bill, changeCallback: (entity: Bill) => void) {
        let newData = getState();
        newData.dueDate = date;
        changeCallback(newData)
    }

    addOrder(bill: Bill) {
        orderController.fromBill(bill)
            .then(c => {
                this.props.history.push({
                    pathname: "order",
                    search: "id=" + c.id,
                });
            })
    }

    addOffer(bill: Bill) {
        offerController.fromBill(bill)
            .then(c => {
                this.props.history.push({
                    pathname: "offer",
                    search: "id=" + c.id,
                });
            })
    }

    addDeliveryNote(bill: Bill) {
        if (bill.closed) {
            deliveryNoteController.fromBill(bill)
                .then(c => {
                    this.props.history.push({
                        pathname: "deliveryNote",
                        search: "id=" + c.id,
                    });
                })
        }
        else {
            alert(this.props.t('documents.bill_details.delivery_note_error'))
        }
    }

    addCancellation(bill: Bill) {
        if (bill.closed) {
            cancellationController.fromBill(bill)
                .then(c => {
                    this.props.history.push({
                        pathname: "cancellation",
                        search: "id=" + c.id,
                    });
                })
        }
        else {
            alert(this.props.t('documents.bill_details.cancellation_error'))
        }
    }

    renderSpecifics(entity: Bill, getState: () => Bill, changeCallback: (entity: Bill) => void): JSX.Element {
        let date = new Date(Date.parse(entity.dueDate.toString()))
        let dueDateContent = entity.hasDueDate ?
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.due_date')}:</label>
                <DatePicker dateFormat="dd.MM.yyyy"
                    selected={date}
                    onChange={(date: Date) => this.dateChanged(date, getState, changeCallback)} />
            </div>
            : <></>

        let orderContent = entity.orderId === "" ?
            <AddButton id="add-order" onClick={() => this.addOrder(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "order",
                    search: "id=" + entity.orderId,
                }}>
                {entity.orderId}
            </Link>

        let offerContent = entity.offerId === "" ?
            <AddButton id="add-offer" onClick={() => this.addOffer(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "offer",
                    search: "id=" + entity.offerId,
                }}>
                {entity.offerId}
            </Link>

        let deliveryNoteContent = entity.deliveryNoteId === "" ?
            <AddButton id="add-deliveryNote" onClick={() => this.addDeliveryNote(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "deliveryNote",
                    search: "id=" + entity.deliveryNoteId,
                }}>
                {entity.deliveryNoteId}
            </Link>

        let cancellationContent = entity.cancellationId === "" ?
            <AddButton id="add-cancellation" onClick={() => this.addCancellation(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "cancellation",
                    search: "id=" + entity.cancellationId,
                }}>
                {entity.cancellationId}
            </Link>

        return (<>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.has_due_date')}:</label>
                <input className='meta-data-checkbox' type="checkbox" checked={entity.hasDueDate} onChange={(e) => this.hasDueDateChanged(e, getState, changeCallback)} />
            </div>
            {dueDateContent}
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.payed')}:</label>
                <input className='meta-data-checkbox' type="checkbox" checked={entity.payed} onChange={(e) => this.payedChanged(e, getState, changeCallback)} />
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.offer')}:</label>
                {offerContent}
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.order')}:</label>
                {orderContent}
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.delivery_note')}:</label>
                {deliveryNoteContent}
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.cancellation')}:</label>
                {cancellationContent}
            </div>
        </>)
    }

    render() {


        return (
            <DocumentDetails
                {...this.props}
                listPrefix='bills'
                getOne={this.getOne}
                update={this.update}
                close={this.close}
                print={printBill}
                delete={this.delete}
                renderSpecifics={this.renderSpecifics} />
        )
    }

}