import React, { Component } from 'react';
import { CurrentSession } from './declarations/CurrentSession';

import './custom.css'
import { Company, Session } from './declarations/types';
import sessionController from './functions/SessionController';
import { ControllerBase } from './functions/ControllerBase';
import { ProtectedRoute } from './components/Common/Security/ProtectedRoute';
import { Dashboard } from './components/Dashboard/Dashboard';
import { NavMenu } from './components/Navigation/NavMenu';
import { Customers } from './components/Customer/Customers';
import { CustomerDetails } from './components/Customer/CustomerDetails';
import { Articles } from './components/Articles/Articles';
import { ArticleDetails } from './components/Articles/ArticleDetails';
import { MyData } from './components/MyData/MyData';
import { BillList } from './components/Documents/BillList';
import { OfferList } from './components/Documents/OfferList';
import { OfferDetails } from './components/Documents/OfferDetails';
import { OrderList } from './components/Documents/OrderList';
import { BillDetails } from './components/Documents/BillDetails';
import { DeliveryNoteList } from './components/Documents/DeliveryNoteList';
import { DeliveryNoteDetails } from './components/Documents/DeliveryNoteDetails';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OrderDetails } from './components/Documents/OrderDetails';
import { Route } from 'react-router';
import { CancellationList } from './components/Documents/CancellationList';
import { CancellationDetails } from './components/Documents/CancellationDetails';
import { CreditNoteList } from './components/Documents/CreditNoteList';
import { CreditNoteDetails } from './components/Documents/CreditNoteDetails';
import companyController from './functions/CompanyController';

interface IAppProps extends WithTranslation {
}

interface IAppState {
  currentSession: CurrentSession,
  companies: Company[],
  currentCompany: Company | undefined,
}

class App extends Component<IAppProps, IAppState> {

  constructor(props: IAppProps) {
    super(props)

    this.state = {
      currentSession: new CurrentSession(null),
      companies: [],
      currentCompany: undefined
    }

    this.authenticationStatusChanged = this.authenticationStatusChanged.bind(this);
    this.companyChanged = this.companyChanged.bind(this);
    this.logout = this.logout.bind(this);
  }

  //Executed the first time this component is used
  componentDidMount() {
    //Try to receive the information about a logged in user from the session storage
    let has_valid_session = false;
    let currentSession: CurrentSession | null = null;
    let session: Session | null = null;
    let session_data = window.sessionStorage.getItem('session');
    if (session_data !== 'null' && session_data !== 'undefined' && session_data !== null && session_data !== undefined) {
      session = JSON.parse(session_data);
      currentSession = session !== null ? new CurrentSession(session) : null;
      has_valid_session = currentSession !== null && currentSession.hasValidToken();
    }

    //If a logged user has been found in the session storage set it
    if (has_valid_session) {
      this.authenticationStatusChanged(session);
    }
    //If not try to do a silent authentication with the cookie
    else {
      sessionController.silentAuthenticate()
        .then(response => this.authenticationStatusChanged(response))
        .catch(err => {
          this.setState({
            currentSession: new CurrentSession(null),
          })
        })
    }
  }

  //After a successful login we set the isAuthenticated state to true
  async authenticationStatusChanged(session: Session | null) {
    if (session !== null) {
      window.sessionStorage.setItem('session', JSON.stringify(session))
    }

    let currentSession = new CurrentSession(session);
    ControllerBase.currentSession = currentSession;

    if (session !== null) {
      await companyController.getAll()
        .then(data => {
          let accountId = window.sessionStorage.getItem('accountId');
          let selected = data.find(c => c.id === accountId)
          this.companyChanged(selected);
          this.setState({
            companies: data,
            currentSession
          })
        })
        .catch(err => console.error(err))
    }
    else {
      this.setState({
        currentSession,
      })
    }
  }

  logout() {
    sessionController.quit().then(() => {
      this.authenticationStatusChanged(null);
    });
  }

  companyChanged(company: Company | undefined) {
    ControllerBase.currentCompany = company;
    if (company !== undefined && company.id !== null) {
      window.sessionStorage.setItem('accountId', company.id)
      this.setState({ currentCompany: company })
    }
    else {
      window.sessionStorage.removeItem('accountId')
      this.setState({ currentCompany: undefined })
    }
  }

  render() {
    return (
      <div className="app">
        <Route path='/' render={(props) => (
          <NavMenu {...props}  {...this.props} {...this.state}
            changeCompany={() => this.companyChanged(undefined)}
            logout={this.logout} />)} />
        <div className="mainComp">
          <ProtectedRoute exact={true} path='/' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <Dashboard {...props} {...trans} />}  {...this.props} />
          <ProtectedRoute path='/bills' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <BillList {...props} {...trans} />} {...this.props} />
          <ProtectedRoute path='/bill' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <BillDetails {...props} {...trans} />} {...this.props} />
          <ProtectedRoute path='/deliveryNotes' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <DeliveryNoteList {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/deliveryNote' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <DeliveryNoteDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/offers' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <OfferList {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/offer' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <OfferDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/orders' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <OrderList {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/order' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <OrderDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/cancellations' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <CancellationList {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/cancellation' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <CancellationDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/creditnotes' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <CreditNoteList {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/creditnote' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <CreditNoteDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/customers' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <Customers {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/customer' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <CustomerDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/articles' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <Articles {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/article' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <ArticleDetails {...props}{...trans} />} {...this.props} />
          <ProtectedRoute path='/mydata' authenticatedCallback={this.authenticationStatusChanged}
            {...this.state}
            companySelectedCallback={this.companyChanged}
            render={(props, trans) => <MyData {...props}{...trans} companyData={this.state.currentCompany} />} {...this.props} />
        </div>
      </div>
    );
  }
}

export const AppComp = withTranslation()(App)