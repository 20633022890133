import React, { Component } from 'react';
import { IEntity, SearchElement } from '../../../declarations/types';
import { ImportButton } from '../Buttons/ImportButton';
import { WithTranslation } from 'react-i18next';

import "./SearchInput.css"

interface ISearchInputProps<T extends IEntity> extends WithTranslation {
    id: string,
    elements: SearchElement<T>[],
    selectElement: (e: SearchElement<T> | undefined) => void,
    selectedElement: SearchElement<T> | undefined,
}

interface ISearchInputState<T> {
    currentElement: SearchElement<T> | undefined
}

export class SearchInput<T extends IEntity> extends Component<ISearchInputProps<T>, ISearchInputState<T>>  {

    inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: ISearchInputProps<T>) {
        super(props);

        this.state = {
            currentElement: undefined
        };

        this.elementChanged = this.elementChanged.bind(this)
        this.selectElement = this.selectElement.bind(this)
    }

    elementChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let selectedElement = this.props.elements.find(elem => elem.name === e.target.value)
        this.setState({ currentElement: selectedElement })
    }

    selectElement() {
        this.props.selectElement(this.state.currentElement)
        this.inputRef.current!.value = ""
        this.setState({ currentElement: undefined })
    }

    render() {
        return (
            <div className='meta-data-import '>
                <div className='meta-data-property'>
                    <label className='meta-data-label'>{this.props.t('common.import_data')}:</label>
                    <input type="text" className='meta-data-input' list="data" onChange={this.elementChanged} ref={this.inputRef} />
                    <datalist id="data">
                        {this.props.elements.map(elem => (
                            <option value={elem.name} key={elem.name}>{elem.name}</option>
                        ))}
                    </datalist>
                </div>
                <ImportButton id="import-customer-button" onClick={this.selectElement} />
            </div>
        )
    }
}