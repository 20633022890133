import { CurrentSession } from "../declarations/CurrentSession";
import { Company } from "../declarations/types";

export class ControllerBase {
    //The version of the rest api which is in use
    static apiVersion = 'v1';

    //The current session
    public static currentSession: CurrentSession = new CurrentSession(null);

    public static currentCompany: Company | undefined = undefined;
}