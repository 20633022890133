import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Order, defaultBillingDocument } from '../../declarations/types';
import orderController from '../../functions/OrderController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface OrderListProps extends RouteComponentProps<any>, WithTranslation {
}

interface OrderListState {
}

export class OrderList extends Component<OrderListProps, OrderListState> {

    constructor(props: OrderListProps) {
        super(props);

        this.state = {
        };
    }

    createDocument(): Promise<Order> {
        let entity: Order = {
            billId: "",
            offerId: "",
            ...defaultBillingDocument
        }
        return orderController.create(entity)
    }

    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="order"
                createDocument={this.createDocument}
                loadDocuments={() => orderController.getAll()} />
        )
    }

}