import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Session } from '../../declarations/types';
import sessionController from '../../functions/SessionController';
import { WithTranslation } from 'react-i18next';
import "./Login.css";
import { MsSignInButton } from './MsSignInButton';
import { AuthenticationResult } from '@azure/msal-browser';

interface ILoginProps extends RouteComponentProps<any>, WithTranslation {
    redirect: string | undefined,
    authenticatedCallback: (session: Session) => void,
}

interface ILoginState {
    username: string,
    loginKey: string,
    error_msg: string,
}

export class Login extends Component<ILoginProps, ILoginState> {

    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            username: '',
            loginKey: '',
            error_msg: '',
        };

        this.login = this.login.bind(this);
        this.msAuthCallback = this.msAuthCallback.bind(this);
        this.usernameChanged = this.usernameChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
    }

    componentDidMount() {
        this.setState({
            username: '',
            loginKey: '',
            error_msg: '',
        })
    }

    usernameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ username: e.target.value })
    }

    passwordChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ loginKey: e.target.value })
    }

    login() {
        sessionController.authenticate(this.state.username, this.state.loginKey).then(response => {
            this.props.authenticatedCallback(response)

            if (this.props.redirect === undefined) {
                this.props.history.replace('/');
            }
            else {
                this.props.history.replace(this.props.redirect);
            }
        }).catch(err => {
            console.error(err);
            this.setState({
                error_msg: err,
            })
        })
    }

    msAuthCallback(msResponse: AuthenticationResult) {
        sessionController.authenticateMS(this.state.username, msResponse).then(response => {
            this.props.authenticatedCallback(response)

            if (this.props.redirect === undefined) {
                this.props.history.replace('/');
            }
            else {
                this.props.history.replace(this.props.redirect);
            }
        }).catch(err => {
            console.error(err);
            this.setState({
                error_msg: err,
            })
        })
    }

    render() {
        return (
            <div id="Login-Page">
                <div id="Login-Area">
                    <input
                        id="user-input"
                        placeholder={this.props.t('common.name')}
                        value={this.state.username}
                        onChange={this.usernameChanged}>
                    </input>

                    <input
                        type="password"
                        id="password-input"
                        placeholder={this.props.t('common.password')}
                        value={this.state.loginKey}
                        onChange={this.passwordChanged}>
                    </input>
                    <button id="login-button" onClick={this.login}>{this.props.t('common.log_in')}</button>
                    <MsSignInButton id="ms-button" {...this.props} authenticatedCallback={this.msAuthCallback} />
                </div>
                <h4 id="error-message">{this.state.error_msg}</h4>

            </div >
        )
    }

}