export interface IEntity {
    id: string | null;
}

export interface Session extends IEntity {
    sessionId: string;
    sessionToken: string;
}

export interface BillingJwtToken {
    exp: number,
    iat: number,
    nbf: number,
    role: string[],
    unique_name: string,
}

export interface Address {
    name: string,
    nameSuffix: string,
    street: string,
    number: string,
    additionalLine: string | null,
    zipCode: string,
    city: string,
}

export const defaultAddress: Address = {
    name: "",
    nameSuffix: "",
    street: "",
    number: "",
    additionalLine: "",
    city: "",
    zipCode: "",
}

export interface AccountInformation {
    creditInstitute: string,
    IBAN: string,
    BIC: string
}

export const defaultAccountInformation: AccountInformation = {
    BIC: "",
    IBAN: "",
    creditInstitute: "",
}

export interface LegalPerson extends IEntity {
    address: Address,
    mail: string,
    phone: string,
    mobile: string,
    fax: string,
    VAT_ID: string,
    account: AccountInformation,
}

export interface Company extends LegalPerson {
    companyName: string,
    defaultVAT: number,
    defaultTextOrderTop: string,
    defaultTextOrderBottom: string,
    defaultTextOfferTop: string,
    defaultTextOfferBottom: string,
    defaultTextBillTop: string,
    defaultTextBillBottom: string,
    defaultTextDeliveryNoteTop: string,
    defaultTextDeliveryNoteBottom: string,
    defaultTextCancellationTop: string,
    defaultTextCancellationBottom: string,
    defaultTextCreditNoteTop: string,
    defaultTextCreditNoteBottom: string,
    documentAccentColor: string,
    documentAccentFontColor: string,
    combineShipmentAndPackageCost: boolean,
    billNumberFormat: string,
    creditNoteNumberFormat: string,
    orderNumberFormat: string,
    offerNumberFormat: string,
}

export const defaultCompany: Company = {
    id: null,
    companyName: "",
    mail: "",
    phone: "",
    VAT_ID: "",
    address: defaultAddress,
    fax: "",
    mobile: "",
    account: defaultAccountInformation,
    defaultVAT: 19,
    defaultTextOrderTop: "",
    defaultTextOrderBottom: "",
    defaultTextOfferTop: "",
    defaultTextOfferBottom: "",
    defaultTextBillTop: "",
    defaultTextBillBottom: "",
    defaultTextDeliveryNoteTop: "",
    defaultTextDeliveryNoteBottom: "",
    defaultTextCancellationTop: "",
    defaultTextCancellationBottom: "",
    defaultTextCreditNoteTop: "",
    defaultTextCreditNoteBottom: "",
    documentAccentColor: "",
    documentAccentFontColor: "",
    combineShipmentAndPackageCost: true,
    billNumberFormat: "",
    creditNoteNumberFormat: "",
    orderNumberFormat: "",
    offerNumberFormat: "",
}

export interface Customer extends LegalPerson {
}

export const defaultCustomer: Customer = {
    id: null,
    mail: "",
    phone: "",
    VAT_ID: "",
    address: defaultAddress,
    fax: "",
    mobile: "",
    account: defaultAccountInformation,
}

export interface BillingDocumentPosition {
    id: string,
    description: string,
    count: number,
    price: number,
}

export const defaultBillingDocumentPosition: BillingDocumentPosition = {
    id: "",
    description: "Neue Position",
    count: 1,
    price: 1,
}

export interface AdditionalCosts {
    shipmentAndPackagingAsOnePosition: boolean,
    shipping: number,
    packaging: number,
    advice: number
}

export const defaultAdditionalCosts: AdditionalCosts = {
    shipmentAndPackagingAsOnePosition: true,
    shipping: 0,
    packaging: 0,
    advice: 0
}

export interface BillingDocument extends IEntity {
    closed: boolean,
    referenceNumber: string,
    date: Date,
    addedTextTop: string,
    addedTextBottom: string,
    company: Company,
    customer: Customer,
    deliveryAddress: Address,
    differentDeliveryAddress: boolean,
    intraCommunitySupply: boolean,
    VAT: number,
    positions: BillingDocumentPosition[],
    additionalCosts: AdditionalCosts
}

export const defaultBillingDocument: BillingDocument = {
    closed: false,
    id: null,
    referenceNumber: "",
    date: new Date(Date.now()),
    addedTextTop: "",
    addedTextBottom: "",
    company: defaultCompany,
    customer: defaultCustomer,
    deliveryAddress: defaultAddress,
    differentDeliveryAddress: false,
    intraCommunitySupply: false,
    VAT: 0,
    positions: [],
    additionalCosts: defaultAdditionalCosts
}

export interface Order extends BillingDocument {
    offerId: string,
    billId: string,
}

export interface Offer extends BillingDocument {
    orderId: string,
    billId: string,
}

export interface Bill extends BillingDocument {
    hasDueDate: boolean,
    dueDate: Date,
    payed: boolean,
    offerId: string,
    orderId: string,
    deliveryNoteId: string,
    cancellationId: string,
}

export interface DeliveryNote extends BillingDocument {
    billId: string,
}

export interface Cancellation extends BillingDocument {
    billId: string,
}

export interface CreditNote extends BillingDocument {
}

export interface Article extends IEntity {
    description: string,
    price: number
}

export const defaultArticle: Article = {
    id: null,
    description: "",
    price: 0
}

export interface BillingDocumentText extends IEntity {
    value: string,
}

export const defaultBillingDocumentText: BillingDocumentText = {
    id: null,
    value: "",
}

export interface SearchElement<T> {
    name: string,
    value: T
}

export interface BillingDocumentValues {
    Shipping: string,
    Packaging: string,
    Advice: string,
    PositionSum: string,
    VAT: string,
    OverallSum: string
}
