import React, { Component } from 'react';
import { BiImport } from "react-icons/bi";

import './Buttons.css';

interface IImportButtonProps {
    id: string,
    type: "button" | "submit" | "reset" | undefined
    onClick: () => void,
}

interface IImportButtonState {
}

export class ImportButton extends Component<IImportButtonProps, IImportButtonState> {

    public static defaultProps = {
        type: "button",
    };

    render() {
        return (
            <button id={this.props.id} className="icon-button" type={this.props.type}>
                <BiImport className="icon"
                    onClick={this.props.onClick} />
            </button>
        )
    }
}