import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BillingDocumentPosition } from '../../../declarations/types';
import { euroFormatter } from '../../../functions/Format';
import { CancelButton } from '../../Common/Buttons/CancelButton';
import { DeleteButton } from '../../Common/Buttons/DeleteButton';
import { EditButton } from '../../Common/Buttons/EditButton';
import { SaveButton } from '../../Common/Buttons/SaveButton';
import { WithTranslation } from 'react-i18next';

import "./DocumentPositionComponent.css";

interface IDocumentPositionComponentProps extends RouteComponentProps<any>, WithTranslation {
    position: BillingDocumentPosition,
    updatePosition: (position: BillingDocumentPosition) => void,
    removePosition: (position: BillingDocumentPosition) => void,
}

interface IDocumentPositionComponentState {
    description: string,
    count: number,
    price: number,
    editMode: boolean,
}

export class DocumentPositionComponent extends Component<IDocumentPositionComponentProps, IDocumentPositionComponentState> {

    constructor(props: IDocumentPositionComponentProps) {
        super(props);

        this.state = {
            description: "",
            count: 0,
            price: 0,
            editMode: false,
        };

        this.countChanged = this.countChanged.bind(this);
        this.priceChanged = this.priceChanged.bind(this);
        this.descriptionChanged = this.descriptionChanged.bind(this);
        this.submit = this.submit.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.setPositionData = this.setPositionData.bind(this);
        this.cancel = this.cancel.bind(this);
        this.removePosition = this.removePosition.bind(this);
    }

    componentDidMount() {
        this.setPositionData(this.props.position)
    }

    componentDidUpdate(prevProps: IDocumentPositionComponentProps, prevState: IDocumentPositionComponentState) {
        if (prevProps !== this.props) {
            this.setPositionData(this.props.position)
        }
    }

    setPositionData(position: BillingDocumentPosition) {
        this.setState({
            description: position.description,
            count: position.count,
            price: position.price,
        })
    }

    countChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let count = parseFloat(e.target.value)
        this.setState({ count });
    }

    priceChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let price = parseFloat(e.target.value)
        this.setState({ price });
    }

    descriptionChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            description: e.target.value,
        });
    }

    cancel() {
        this.setPositionData(this.props.position);
        this.toggleEdit();
    }

    toggleEdit() {
        this.setState({ editMode: !this.state.editMode })
    }

    removePosition() {
        this.props.removePosition(this.props.position);
    }

    submit(e: React.FormEvent | undefined) {
        e?.preventDefault();
        let newPosition = this.props.position;
        newPosition.description = this.state.description;
        newPosition.price = isNaN(this.state.price) ? 0 : this.state.price;
        newPosition.count = isNaN(this.state.count) ? 0 : this.state.count;
        this.props.updatePosition(newPosition);
        this.toggleEdit();
    }

    render() {
        let price = isNaN(this.state.price) ? 0 : this.state.price;
        let price_value = isNaN(this.state.price) ? "" : this.state.price;
        let count = isNaN(this.state.count) ? 0 : this.state.count;
        let count_value = isNaN(this.state.count) ? "" : this.state.count;
        let value = count * price;
        let content = this.state.editMode ?
            <form className="document-position-edit element-container meta-data-form" onSubmit={this.submit}>

                <div className='meta-data-property'>
                    <label className='meta-data-label'>{this.props.t('common.description')}:</label>
                    <textarea id="info-text" placeholder={"..."}
                        value={this.state.description} onChange={this.descriptionChanged} />
                </div>


                <div className='meta-data-property'>
                    <label className='meta-data-label'>{this.props.t('common.count')}:</label>
                    <input type='number'
                        step="0.01" className='meta-data-input'
                        value={count_value} onChange={this.countChanged} />
                </div>

                <div className='meta-data-property'>
                    <label className='meta-data-label'>{this.props.t('common.price')}:</label>
                    <input type='number'
                        step="0.01" className='meta-data-input'
                        value={price_value} onChange={this.priceChanged} />
                </div>

                <div className="vertical-button-line">
                    <CancelButton id='cancel-position-button' type='button' onClick={this.cancel} />
                    <SaveButton id='save-position-button' type="submit" onClick={() => this.submit(undefined)} />
                </div>
            </form >
            :
            <div className="document-position element-container" >
                <label className='position-description'>{this.state.description}</label>
                <label className='position-count'>{count}</label>
                <label className='position-price'>{euroFormatter.format(price)}</label>
                <label className='position-value'>{euroFormatter.format(value)}</label>

                <EditButton id='edit-position-button' onClick={this.toggleEdit} />
                <DeleteButton id='delete-position-button' onClick={this.removePosition} />
            </div >

        return (content)
    }
}