import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CreditNote, defaultBillingDocument } from '../../declarations/types';
import creditNoteController from '../../functions/CreditNoteController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface CreditNoteListProps extends RouteComponentProps<any>, WithTranslation {
}

interface CreditNoteListState {
}

export class CreditNoteList extends Component<CreditNoteListProps, CreditNoteListState> {

    constructor(props: CreditNoteListProps) {
        super(props);

        this.state = {
        };
    }

    createDocument(): Promise<CreditNote> {
        let entity: CreditNote = {
            ...defaultBillingDocument
        }
        return creditNoteController.create(entity)
    }

    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="creditNote"
                createDocument={this.createDocument}
                loadDocuments={() => creditNoteController.getAll()} />
        )
    }

}