import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Article, defaultArticle } from '../../declarations/types';
import articleController from '../../functions/ArticleController';
import { SearchList } from '../Common/List/SearchList';
import { WithTranslation } from 'react-i18next';

import "./Articles.css";

interface IArticlesProps extends RouteComponentProps<any>, WithTranslation {
}

interface IArticlesState {
    articles: Article[],
}

export class Articles extends Component<IArticlesProps, IArticlesState> {

    constructor(props: IArticlesProps) {
        super(props);

        this.state = {
            articles: []
        };

        this.loadData = this.loadData.bind(this);
        this.createArticle = this.createArticle.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    compareArticles(a: Article, b: Article){
        return a.description.localeCompare(b.description)
    }

    loadData() {
        articleController.getAll()
            .then(articles => {
                articles = articles.sort(this.compareArticles)
                this.setState({ articles })
            })
            .catch(err => console.log(err));
    }

    createArticle() {
        articleController.create(defaultArticle)
            .then(c => {
                this.props.history.push({
                    pathname: "/article",
                    search: "id=" + c.id,
                });
            })
    }

    filterArticle(article: Article, searchText: string) {
        return article.description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    }

    render() {
        return (
            <div id="Articles">
                <SearchList
                    {...this.props}
                    id="article-list"
                    addNew={this.createArticle}
                    filterFunction={this.filterArticle}
                    elements={this.state.articles}
                    renderElement={a => {
                        return (
                            <div key={a.id} className='article-data'>
                                <Link className="article-name"
                                    to={{
                                        pathname: "/article",
                                        search: "id=" + a.id,
                                    }}>
                                    <label>{a.description}</label>
                                </Link>
                            </div>
                        )
                    }} />
            </div >
        )
    }
}