import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Company } from '../../declarations/types';

import "./CompanySelection.css";

interface ICompanySelectionProps extends WithTranslation {
    companies: Company[],
    currentCompany: Company | undefined,
    companySelectedCallback: (company: Company | undefined) => void,
}

interface ICompanySelectionState {
    selected: Company | undefined
}

export class CompanySelection extends Component<ICompanySelectionProps, ICompanySelectionState> {

    constructor(props: ICompanySelectionProps) {
        super(props);

        this.state = {
            selected: undefined,
        };

        this.companyChanged = this.companyChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        if (this.props.companies.length > 0) {
            let selected = this.props.companies[0]
            this.setState({ selected })
        }
    }

    companyChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let company = this.props.companies.find(c => c.companyName === e.target.value)
        this.setState({ selected: company })
    }

    submit(e: React.FormEvent) {
        e.preventDefault();
        this.props.companySelectedCallback(this.state.selected);
    }

    render() {
        return (
            <div id="Company-Selection-Page">
                <form id="Company-Selection-Area" onSubmit={this.submit}>
                    <select id="company-selection" value={this.state.selected?.companyName} onChange={this.companyChanged}>
                        {this.props.companies.map(y => <option value={y.companyName} key={y.companyName}>{y.companyName}</option>)}
                    </select>
                    <button id="select-button" type="submit">{this.props.t('common.continue')}</button>
                </form>
            </div >
        )
    }
}