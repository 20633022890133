import { Bill, Offer, Order } from "../declarations/types";
import { DocumentControllerBase } from "./DocumentControllerBase";

class BillController extends DocumentControllerBase<Bill> {

    fromOffer(entity: Offer): Promise<Bill> {
        return this.postWithToken(this.getRoute("fromOffer"), entity)
    }

    fromOrder(entity: Order): Promise<Bill> {
        return this.postWithToken(this.getRoute("fromOrder"), entity)
    }

}

const billController = new BillController('bill');
export default billController;