export const euroFormatter = new Intl.NumberFormat('de-De', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export const numberFormatter = new Intl.NumberFormat('de-De', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

export const dateFormatter = new Intl.DateTimeFormat("de-De", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
});