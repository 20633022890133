import { Bill, Cancellation } from "../declarations/types";
import { DocumentControllerBase } from "./DocumentControllerBase";

class CancellationController extends DocumentControllerBase<Cancellation> {

    fromBill(entity: Bill): Promise<Cancellation> {
        return this.postWithToken(this.getRoute("fromBill"), entity)
    }

}

const cancellationController = new CancellationController('cancellation');
export default cancellationController;