import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Customer, defaultCustomer } from '../../declarations/types';
import customerController from '../../functions/CustomerController';
import { WithTranslation } from 'react-i18next';
import "./Customers.css";
import { SearchList } from '../Common/List/SearchList';

interface ICustomersProps extends RouteComponentProps<any>, WithTranslation {
}

interface ICustomersState {
    customers: Customer[]
}

export class Customers extends Component<ICustomersProps, ICustomersState> {

    constructor(props: ICustomersProps) {
        super(props);

        this.state = {
            customers: []
        };

        this.loadData = this.loadData.bind(this);
        this.createCustomer = this.createCustomer.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    compareCustomers(a: Customer, b: Customer) {
        return a.address.name.localeCompare(b.address.name)
    }

    loadData() {
        customerController.getAll()
            .then(customers => {
                customers = customers.sort(this.compareCustomers)
                this.setState({ customers })
            })
            .catch(err => console.log(err));
    }

    createCustomer() {
        customerController.create(defaultCustomer)
            .then(c => {
                this.props.history.push({
                    pathname: "/customer",
                    search: "id=" + c.id,
                });
            })
    }

    filterCustomer(customer: Customer, searchText: string) {
        return customer.address.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    }

    render() {
        return (
            <div id="Customers">
                <SearchList
                    {...this.props}
                    id="customer-list"
                    addNew={this.createCustomer}
                    filterFunction={this.filterCustomer}
                    elements={this.state.customers}
                    renderElement={c => {
                        let mailTo = c.mail ? 'mailto:' + c.mail : undefined;
                        let phoneTo = c.phone ? 'tel:' + c.phone.replace('', '').replace('/', '') : undefined;
                        let mobileTo = c.mobile ? 'tel:' + c.mobile.replace('', '').replace('/', '') : undefined;
                        return (
                            <div key={c.id} className='customer-data'>
                                <Link className="customer-name"
                                    to={{
                                        pathname: "/customer",
                                        search: "id=" + c.id,
                                    }}>
                                    <label>{c.address.name}</label>
                                </Link>
                                <a className='customer-mail' href={mailTo}>{c.mail}</a>
                                <a className='customer-phone' href={phoneTo}>{c.phone}</a>
                                <a className='customer-mobile' href={mobileTo}>{c.mobile}</a>
                            </div>
                        )
                    }} />
            </div >
        )
    }

}