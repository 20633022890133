import React, { Component } from 'react';
import { BsPrinter } from "react-icons/bs";

import './Buttons.css';

interface IPrintButtonProps {
    id: string,
    type: "button" | "submit" | "reset" | undefined
    onClick: () => void,
}

interface IPrintButtonState {
}

export class PrintButton extends Component<IPrintButtonProps, IPrintButtonState> {

    public static defaultProps = {
        type: "button",
    };

    render() {
        return (
            <button id={this.props.id} className="icon-button" type={this.props.type}>
                <BsPrinter className="icon"
                    onClick={this.props.onClick} />
            </button>
        )
    }
}