import React, { Component } from 'react';
import { BiEdit } from "react-icons/bi";

import './Buttons.css';

interface IEditButtonProps {
    id: string,
    type: "button" | "submit" | "reset" | undefined
    onClick: () => void,
}

interface IEditButtonState {
}

export class EditButton extends Component<IEditButtonProps, IEditButtonState> {

    public static defaultProps = {
        type: "button",
    };

    render() {
        return (
            <button id={this.props.id} className="icon-button" type={this.props.type}>
                <BiEdit className="icon"
                    height="30px"
                    width="30px"
                    onClick={this.props.onClick} />
            </button>
        )
    }
}