import React, { Component } from 'react';

import "./TabControl.css"

interface ITabControlProps {
    tabs: Map<string, JSX.Element>,
    defaultSelection: string,
}

interface ITabControlState {
    selectedTab: string,
}

export class TabControl extends Component<ITabControlProps, ITabControlState>  {

    constructor(props: ITabControlProps) {
        super(props);

        this.state = {
            selectedTab: this.props.defaultSelection,
        };
    }

    selectTab(selectedTab: string) {
        this.setState({selectedTab});
    }

    render() {
        let tabHeaders: string[] = Array.from(this.props.tabs.keys());
        return (
            <div className="tab-control">
                <div className="tab-bar">
                    {tabHeaders.map(h => {
                        let className = this.state.selectedTab === h ? "tab-header active" : "tab-header";
                        return (
                            <button key={h} className={className} onClick={() => this.selectTab(h)}>{h}</button>
                        )
                    })}
                </div>
                <div className="tab-content">
                    {this.props.tabs.get(this.state.selectedTab)}
                </div>
            </div>
        )
    }

}