import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import cancellationController from '../../functions/CancellationController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface CancellationListProps extends RouteComponentProps<any>, WithTranslation {
}

interface CancellationListState {
}

export class CancellationList extends Component<CancellationListProps, CancellationListState> {

    constructor(props: CancellationListProps) {
        super(props);

        this.state = {
        };
    }


    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="cancellation"
                createDocument={undefined}
                loadDocuments={() => cancellationController.getAll()} />
        )
    }

}