import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Company, defaultCompany, defaultBillingDocumentText, BillingDocumentText } from '../../declarations/types';
import companyController from '../../functions/CompanyController';
import documentTextController from '../../functions/DocumentTextController';
import { AddButton } from '../Common/Buttons/AddButton';
import { SaveButton } from '../Common/Buttons/SaveButton';
import { TabControl } from '../Common/TabControl';
import { WithTranslation } from 'react-i18next';
import { ColorResult, SketchPicker } from 'react-color';

import "./MyData.css";
import { StandardText } from './StandardText';
import { ControllerBase } from '../../functions/ControllerBase';

interface IMyDataProps extends RouteComponentProps<any>, WithTranslation {
    companyData: Company | undefined,
}

interface IMyDataState {
    companyData: Company,
    documentTexts: BillingDocumentText[]
}

export class MyData extends Component<IMyDataProps, IMyDataState> {

    constructor(props: IMyDataProps) {
        super(props);

        this.state = {
            companyData: defaultCompany,
            documentTexts: []
        };

        this.loadData = this.loadData.bind(this);
        this.createText = this.createText.bind(this);
        this.save = this.save.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.streetChanged = this.streetChanged.bind(this);
        this.numberChanged = this.numberChanged.bind(this);
        this.zipCodeChanged = this.zipCodeChanged.bind(this);
        this.cityChanged = this.cityChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        this.mobileChanged = this.mobileChanged.bind(this);
        this.faxChanged = this.faxChanged.bind(this);
        this.mailChanged = this.mailChanged.bind(this);
        this.vatChanged = this.vatChanged.bind(this);
        this.creditInstituteChanged = this.creditInstituteChanged.bind(this);
        this.ibanChanged = this.ibanChanged.bind(this);
        this.bicChanged = this.bicChanged.bind(this);
        this.defaultVATChanged = this.defaultVATChanged.bind(this);
        this.documentAccentColorChanged = this.documentAccentColorChanged.bind(this);
        this.documentAccentFontColorChanged = this.documentAccentFontColorChanged.bind(this);
        this.offerNumberFormatChanged = this.offerNumberFormatChanged.bind(this);
        this.orderNumberFormatChanged = this.orderNumberFormatChanged.bind(this);
        this.billNumberFormatChanged = this.billNumberFormatChanged.bind(this);
        this.creditNoteNumberFormatChanged = this.creditNoteNumberFormatChanged.bind(this);
        this.combineShipmentAndPackageCostChanged = this.combineShipmentAndPackageCostChanged.bind(this);
        this.defaultTextBillTopChanged = this.defaultTextBillTopChanged.bind(this);
        this.defaultTextBillBottomChanged = this.defaultTextBillBottomChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        if (this.props.companyData !== undefined) {
            this.setState({ companyData: this.props.companyData })
        }
        this.loadData();
    }

    componentDidUpdate(prevProps: IMyDataProps, prevState: IMyDataState) {
        if (this.props.companyData !== prevProps.companyData) {
            if (this.props.companyData !== undefined) {
                this.setState({ companyData: this.props.companyData })
            }
        }
    }

    loadData() {
        documentTextController.getAll()
            .then(data => {
                this.setState({ documentTexts: data })
            })
            .catch(err => console.log(err));
    }

    save() {
        if (this.state.companyData.id === null) {
            console.error("Company data object may not be created from the client.")
        }
        else {
            companyController.update(this.state.companyData.id, this.state.companyData)
                .then(companyData => {
                    ControllerBase.currentCompany = companyData
                    this.setState({ companyData })
                })
                .catch(err => console.error(err))
        }
    }

    createText() {
        documentTextController.create(defaultBillingDocumentText)
            .then(c => this.loadData())
    }

    nameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.address.name = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    streetChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.address.street = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    numberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.address.number = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    zipCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.address.zipCode = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    cityChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.address.city = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    phoneChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.phone = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    mobileChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.mobile = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    faxChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.fax = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    mailChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.mail = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    vatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.VAT_ID = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    creditInstituteChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.account.creditInstitute = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    bicChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.account.BIC = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    ibanChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.account.IBAN = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    defaultVATChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.defaultVAT = parseFloat(e.target.value);
        this.setState({
            companyData: newCompanyData,
        });
    }

    documentAccentColorChanged(e: ColorResult) {
        let newCompanyData = this.state.companyData;
        newCompanyData.documentAccentColor = e.hex;
        this.setState({
            companyData: newCompanyData,
        });
    }

    documentAccentFontColorChanged(e: ColorResult) {
        let newCompanyData = this.state.companyData;
        newCompanyData.documentAccentFontColor = e.hex;
        this.setState({
            companyData: newCompanyData,
        });
    }

    billNumberFormatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.billNumberFormat = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    orderNumberFormatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.orderNumberFormat = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    offerNumberFormatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.offerNumberFormat = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    creditNoteNumberFormatChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.creditNoteNumberFormat = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    combineShipmentAndPackageCostChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.combineShipmentAndPackageCost = !this.state.companyData.combineShipmentAndPackageCost;
        this.setState({
            companyData: newCompanyData,
        });
    }

    defaultTextBillTopChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.defaultTextBillTop = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    defaultTextBillBottomChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let newCompanyData = this.state.companyData;
        newCompanyData.defaultTextBillBottom = e.target.value;
        this.setState({
            companyData: newCompanyData,
        });
    }

    submit(e: React.FormEvent) {
        e.preventDefault();
        this.save();
    }

    render() {

        return (
            <>
                <div id="MyData" >
                    <TabControl tabs={
                        new Map<string, JSX.Element>([
                            [this.props.t('common.contact_data'),
                            <fieldset className="meta-data-form tab-item">
                                <legend>{this.props.t('common.contact_data')}</legend>
                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.name')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.address.name} onChange={this.nameChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.street')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.address.street} onChange={this.streetChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.number')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.address.number} onChange={this.numberChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.zip_code')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.address.zipCode} onChange={this.zipCodeChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.city')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.address.city} onChange={this.cityChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.phone')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.phone} onChange={this.phoneChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.mobile')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.mobile} onChange={this.mobileChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.fax')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.fax} onChange={this.faxChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.mail')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.mail} onChange={this.mailChanged} />
                                </div>
                            </fieldset>],
                            [this.props.t('common.bank_account'),
                            <fieldset className="meta-data-form tab-item">
                                <legend>{this.props.t('common.bank_account')}</legend>
                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.credit_institute')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.account.creditInstitute} onChange={this.creditInstituteChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.iban')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.account.IBAN} onChange={this.ibanChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.bic')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.account.BIC} onChange={this.bicChanged} />
                                </div>
                            </fieldset>],
                            [this.props.t('common.options'),
                            <fieldset className="meta-data-form tab-item">
                                <legend>{this.props.t('common.options')}</legend>
                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.vat_id')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.VAT_ID} onChange={this.vatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.vat_percentage')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.defaultVAT} onChange={this.defaultVATChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.highlicht_color')}:</label>
                                    <SketchPicker className='meta-data-input'
                                        color={this.state.companyData.documentAccentColor}
                                        onChangeComplete={this.documentAccentColorChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.contrast_color')}:</label>
                                    <SketchPicker className='meta-data-input'
                                        color={this.state.companyData.documentAccentFontColor}
                                        onChangeComplete={this.documentAccentFontColorChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.offer_number_format')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.offerNumberFormat} onChange={this.offerNumberFormatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.order_number_format')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.orderNumberFormat} onChange={this.orderNumberFormatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.bill_number_format')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.billNumberFormat} onChange={this.billNumberFormatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.credit_note_number_format')}:</label>
                                    <input type="text" className='meta-data-input'
                                        value={this.state.companyData.creditNoteNumberFormat} onChange={this.creditNoteNumberFormatChanged} />
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('common.combine_positions')}:</label>
                                    <input className='meta-data-checkbox' type="checkbox" checked={this.state.companyData.combineShipmentAndPackageCost} onChange={this.combineShipmentAndPackageCostChanged} />
                                </div>
                            </fieldset>],
                            [this.props.t('common.texts'),
                            <>
                                <fieldset className="meta-data-form tab-item">
                                    <legend>{this.props.t('common.texts')}</legend>
                                    {this.state.documentTexts.map(y => {
                                        return (
                                            <StandardText key={y.id} data={y} />
                                        )
                                    })}
                                </fieldset>
                                <div id="buttons" className="vertical-button-line">
                                    <AddButton id="" onClick={this.createText} />
                                </div>
                            </>],
                            [this.props.t('common.deafult_texts'),
                            <fieldset className="meta-data-form tab-item">
                                <legend>{this.props.t('common.deafult_texts')}</legend>
                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('my_data.bill_top')}:</label>
                                    <select value={this.state.companyData.defaultTextBillTop} onChange={this.defaultTextBillTopChanged}>
                                        {this.state.documentTexts.map(y => <option value={y.value} key={y.id}>{y.value}</option>)}
                                    </select>
                                </div>

                                <div className='meta-data-property'>
                                    <label className='meta-data-label'>{this.props.t('my_data.bill_bottom')}:</label>
                                    <select value={this.state.companyData.defaultTextBillBottom} onChange={this.defaultTextBillBottomChanged}>
                                        {this.state.documentTexts.map(y => <option value={y.value} key={y.id}>{y.value}</option>)}
                                    </select>
                                </div>
                            </fieldset>],
                        ])}
                        defaultSelection={this.props.t('common.contact_data')} />
                </div >

                <div className="tool-bar" >
                    <SaveButton id="save-button" type="submit" onClick={this.save} />
                </div>
            </>
        )
    }
}