import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { SearchList } from '../../Common/List/SearchList';
import { BillingDocument } from '../../../declarations/types';
import { getBillingDocumentValues } from '../../../functions/DocumentUtils';
import { WithTranslation } from 'react-i18next';
import "./DocumentsComp.css";
import { dateFormatter } from '../../../functions/Format';


interface IDocumentsCompProps<T extends BillingDocument> extends RouteComponentProps<any>, WithTranslation {
    detailsPrefix: string,
    createDocument: (() => Promise<T>) | undefined,
    loadDocuments: () => Promise<T[]>
}

interface IDocumentsCompState<T extends BillingDocument> {
    documents: T[]
}

export class DocumentsComp<T extends BillingDocument> extends Component<IDocumentsCompProps<T>, IDocumentsCompState<T>> {

    constructor(props: IDocumentsCompProps<T>) {
        super(props);

        this.state = {
            documents: []
        };

        this.loadData = this.loadData.bind(this);
        this.createDocument = this.createDocument.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    createDocument() {
        if (this.props.createDocument !== undefined) {
            this.props.createDocument()
                .then(c => {
                    this.props.history.push({
                        pathname: this.props.detailsPrefix,
                        search: "id=" + c.id,
                    });
                })
                .catch(err => console.log(err));
        }
    }

    compareDocuments(a: BillingDocument, b: BillingDocument){
        if (!a.closed && !b.closed){
            return 0
        }
        if (a.closed && b.closed){
            return b.referenceNumber.localeCompare(a.referenceNumber)
        }

        if (a.closed){
            return 1
        }
        else{
            return -1
        }
    }

    loadData() {
        this.props.loadDocuments()
            .then(documents => {
                documents = documents.sort(this.compareDocuments)
                this.setState({ documents })
            })
            .catch(err => console.log(err));
    }

    filterDocuments(document: BillingDocument, searchText: string) {
        return document.customer.address.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
            document.deliveryAddress?.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
            document.referenceNumber.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    }

    render() {
        return (
            <div id="Documents">
                <SearchList
                    {...this.props}
                    id="document-list"
                    addNew={this.props.createDocument === undefined ? undefined : this.createDocument}
                    filterFunction={this.filterDocuments}
                    elements={this.state.documents}
                    renderElement={c => {
                        let values = getBillingDocumentValues(c)
                        let referenceNumber = c.closed ? c.referenceNumber : this.props.t("common.open")
                        let date = c.closed ? dateFormatter.format(new Date(Date.parse(c.date.toString()))) : ""
                        return (
                            <div key={c.id} className='document-data'>
                                <Link className="document-number my-clickable"
                                    to={{
                                        pathname: this.props.detailsPrefix,
                                        search: "id=" + c.id,
                                    }}>
                                    <label className="my-clickable">{referenceNumber}</label>
                                </Link>
                                <label className='document-name'>{c.customer.address.name}</label>
                                <label className='document-date'>{date}</label>
                                <label className='document-value'>{values.OverallSum}</label>
                            </div>
                        )
                    }} />
            </div >
        )
    }

}