import React, { Component } from 'react';
import { IEntity } from '../../../declarations/types';
import { AddButton } from '../Buttons/AddButton';
import { WithTranslation } from 'react-i18next';

import "./SearchList.css"

interface ISearchListProps<T extends IEntity> extends WithTranslation {
    id: string,
    elements: T[],
    addNew: (() => void) | undefined,
    filterFunction: (element: T, searchText: string) => boolean,
    renderElement: (e: T) => JSX.Element,
}

interface ISearchListState<T extends IEntity> {
    searchText: string,
    filteredElements: T[],
}

export class SearchList<T extends IEntity> extends Component<ISearchListProps<T>, ISearchListState<T>>  {

    constructor(props: ISearchListProps<T>) {
        super(props);

        this.state = {
            searchText: "",
            filteredElements: [],
        };

        this.updateFilteredElements = this.updateFilteredElements.bind(this);
        this.handleSearchTextChanged = this.handleSearchTextChanged.bind(this);
    }

    componentDidMount() {
        this.updateFilteredElements(this.state.searchText, this.props.elements);
    }

    componentDidUpdate(prevProps: ISearchListProps<T>, prevState: ISearchListState<T>) {
        if (this.props.elements !== prevProps.elements) {
            this.updateFilteredElements(this.state.searchText, this.props.elements);
        }
    }

    handleSearchTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.updateFilteredElements(e.target.value, this.props.elements);
        this.setState({ searchText: e.target.value });
    }

    updateFilteredElements(searchText: string, elements: T[]) {
        let filteredElements = elements.filter(e => this.props.filterFunction(e, searchText));
        this.setState({ filteredElements });
    }

    render() {
        return (
            <div id={this.props.id} className="common-search-list">
                <div id="header">
                    <input id="search-input"
                        value={this.state.searchText}
                        onChange={this.handleSearchTextChanged}
                        placeholder={this.props.t('common.search_placeholder')} />

                    {this.props.addNew !== undefined &&
                        <AddButton id="add-button" onClick={this.props.addNew} />
                    }
                </div>

                <div id="list">
                    {this.state.filteredElements.map(e => {
                        return (
                            <div key={e.id} className="element-container">
                                {this.props.renderElement(e)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}