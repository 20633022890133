import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DeliveryNote } from '../../declarations/types';
import deliveryNoteController from '../../functions/DeliveryNoteController';
import { printDeliveryNote } from '../../functions/DocumentUtils';
import { DocumentDetails } from './Base/DocumentDetails';
import { WithTranslation } from 'react-i18next';

interface DeliveryNoteDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface DeliveryNoteDetailsState {
}

export class DeliveryNoteDetails extends Component<DeliveryNoteDetailsProps, DeliveryNoteDetailsState> {

    constructor(props: DeliveryNoteDetailsProps) {
        super(props);

        this.state = {
        };

        this.renderSpecifics = this.renderSpecifics.bind(this)
    }

    getOne(id: string): Promise<DeliveryNote> {
        return deliveryNoteController.getOne(id)
    }

    update(id: string, entity: DeliveryNote): Promise<DeliveryNote> {
        return deliveryNoteController.update(id, entity)
    }

    close(entity: DeliveryNote): Promise<DeliveryNote> {
        return deliveryNoteController.close(entity)
    }

    delete(id: string): Promise<void> {
        return deliveryNoteController.delete(id)
    }

    renderSpecifics(entity: DeliveryNote, getState: () => DeliveryNote, changeCallback: (entity: DeliveryNote) => void): JSX.Element {

        return (<>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.bill')}:</label>
                <Link className="document-number"
                    to={{
                        pathname: "bill",
                        search: "id=" + entity.billId,
                    }}>
                    {entity.billId}
                </Link>
            </div>
        </>)
    }

    render() {


        return (
            <DocumentDetails
                {...this.props}
                listPrefix='deliveryNotes'
                getOne={this.getOne}
                update={this.update}
                close={this.close}
                print={printDeliveryNote}
                delete={this.delete}
                renderSpecifics={this.renderSpecifics} />
        )
    }

}