import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { CurrentSession } from '../../declarations/CurrentSession';
import './NavMenu.css';
import { Company } from '../../declarations/types';

interface INavMenuProps extends RouteComponentProps<any>, WithTranslation {
    currentSession: CurrentSession,
    currentCompany: Company | undefined,
    changeCompany: () => void,
    logout: () => void,
}

interface INavMenuState {
    collapsed: boolean,
}

export class NavMenu extends Component<INavMenuProps, INavMenuState> {

    constructor(props: INavMenuProps) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };

        this.getActiveClassName = this.getActiveClassName.bind(this)
    }

    getActiveClassName(keyword: string, exact: boolean = false) {
        if ((!exact && this.props.location.pathname.includes(keyword)) ||
            (exact && this.props.location.pathname === keyword)) {
            return "active"
        }
        else {
            return ""
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {

        return (
            <Navbar className="navbar navbar-expand-sm navbar-toggleable-sm" color="dark">
                <NavbarToggler onClick={this.toggleNavbar} className="mr-5 bg-dark navbar-dark" />
                <Collapse className="bg-dark" isOpen={!this.state.collapsed} navbar>
                    <Nav className="ml-auto" navbar>
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("/", true)} to="/">{this.props.t('common.dashboard')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("offer")} to="/offers">{this.props.t('common.offers')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("order")} to="/orders">{this.props.t('common.orders')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("bill")} to="/bills">{this.props.t('common.bills')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("deliveryNote")} to="/deliveryNotes">{this.props.t('common.delivery_notes')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("cancellation")} to="/cancellations">{this.props.t('common.cancellations')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("creditNote")} to="/creditNotes">{this.props.t('common.credit_notes')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("customer")} to="/customers">{this.props.t('common.customers')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <NavItem>
                                <NavLink tag={Link} className={"text-white" + this.getActiveClassName("article")} to="/articles">{this.props.t('common.articles')}</NavLink>
                            </NavItem>
                        }
                        {this.props.currentSession !== null && this.props.currentSession.hasValidToken() && this.props.currentCompany !== undefined &&
                            <UncontrolledDropdown nav inNavbar className="navbar-dark" color="dark">
                                <DropdownToggle nav caret className={"nav-link navbar-dark text-white" + this.getActiveClassName("mydata")} color="dark">
                                    {this.props.t('common.my_data')}
                                </DropdownToggle>
                                <DropdownMenu right className="nav-dropdown">
                                    <DropdownItem key="profile" className="nav-dropdown-item">
                                        <Link className="nav-dropdown-link" to="/mydata">
                                            {this.props.currentCompany?.companyName}
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem key="change_account" className="nav-dropdown-item" onClick={() => this.props.changeCompany()}>
                                        {this.props.t('common.change_account')}
                                    </DropdownItem>
                                    <DropdownItem key="logout" className="nav-dropdown-item" onClick={() => this.props.logout()}>
                                        {this.props.t('common.logout')}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}