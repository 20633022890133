import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Article, defaultArticle } from '../../declarations/types';
import articleController from '../../functions/ArticleController';
import { DeleteButton } from '../Common/Buttons/DeleteButton';
import { SaveButton } from '../Common/Buttons/SaveButton';
import { WithTranslation } from 'react-i18next';
import "./ArticleDetails.css";

interface IArticleDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface IArticleDetailsState {
    data: Article,
}

export class ArticleDetails extends Component<IArticleDetailsProps, IArticleDetailsState> {

    constructor(props: IArticleDetailsProps) {
        super(props);

        this.state = {
            data: defaultArticle,
        };

        this.loadData = this.loadData.bind(this);
        this.save = this.save.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
        this.descriptionChanged = this.descriptionChanged.bind(this);
        this.priceChanged = this.priceChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let newId = this.getQueryId(this.props);
        if (newId !== undefined) {
            this.loadData(newId);
        }
    }

    componentDidUpdate(prevProps: IArticleDetailsProps, prevState: IArticleDetailsState) {
        let oldId = this.getQueryId(prevProps);
        let newId = this.getQueryId(this.props);
        if (newId !== undefined && newId !== oldId) {
            this.loadData(newId);
        }
    }

    getQueryId(props: IArticleDetailsProps): string | undefined {
        const searchParams = new URLSearchParams(props.location.search);
        return searchParams.get("id") || undefined;
    }

    loadData(id: string) {
        articleController.getOne(id)
            .then(data => this.setState({ data }))
            .catch(err => console.log(err));
    }

    save() {
        if (this.state.data.id !== null) {
            articleController.update(this.state.data.id, this.state.data)
                .then(data => this.setState({ data }))
                .catch(err => console.error(err))
        }
        else {
            alert(this.props.t('article_details.no_id_warning'));
        }
    }

    deleteArticle() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(this.props.t('article_details.delete_question'))) {
            if (this.state.data.id !== null) {
                articleController.delete(this.state.data.id)
                    .then(() => {
                        this.props.history.replace({
                            pathname: "/articles",
                            search: '',
                        })
                    })
                    .catch(err => console.error(err))
            }
            else {
                alert(this.props.t('article_details.no_id_warning'));
            }
        }
    }

    descriptionChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        let newData = this.state.data;
        newData.description = e.target.value;
        this.setState({
            data: newData,
        });
    }

    priceChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newData = this.state.data;
        newData.price = parseFloat(e.target.value)
        if (isNaN(newData.price)) {
            newData.price = 0
        }
        this.setState({ data: newData });
    }

    submit(e: React.FormEvent) {
        e.preventDefault();
        this.save();
    }

    render() {

        return (
            <>
                <div id="Article-Details" >
                    <form className="article-edit meta-data-form" onSubmit={this.submit}>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.description')}:</label>
                            <textarea id="info-text" placeholder={"..."}
                                value={this.state.data.description} onChange={this.descriptionChanged} />
                        </div>

                        <div className='meta-data-property'>
                            <label className='meta-data-label'>{this.props.t('common.price')}:</label>
                            <input type='number'
                                step="0.01" className='meta-data-input'
                                value={this.state.data.price} onChange={this.priceChanged} />
                        </div>

                    </form>
                </div >
                <div className="tool-bar">
                    <SaveButton id="save-button" type="submit" onClick={this.save} />
                    <DeleteButton id="delete-button" onClick={this.deleteArticle} />
                </div>
            </>
        )
    }
}