import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppComp } from './App';
import registerServiceWorker from './registerServiceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/Login/MsAuthConfig";
import './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Suspense fallback={<div>Loading... </div>}>
      <MsalProvider instance={msalInstance}>
        <AppComp />
      </MsalProvider>
    </Suspense>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

