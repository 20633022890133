import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import deliveryNoteController from '../../functions/DeliveryNoteController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface DeliveryNoteListProps extends RouteComponentProps<any>, WithTranslation {
}

interface DeliveryNoteListState {
}

export class DeliveryNoteList extends Component<DeliveryNoteListProps, DeliveryNoteListState> {

    constructor(props: DeliveryNoteListProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="deliveryNote"
                createDocument={undefined}
                loadDocuments={() => deliveryNoteController.getAll()} />
        )
    }

}