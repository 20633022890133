import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Order } from '../../declarations/types';
import { printOrder } from '../../functions/DocumentUtils';
import { DocumentDetails } from './Base/DocumentDetails';
import { AddButton } from '../Common/Buttons/AddButton';
import billController from '../../functions/BillController';
import { WithTranslation } from 'react-i18next';
import offerController from '../../functions/OfferController';
import orderController from '../../functions/OrderController';

interface OrderDetailsProps extends RouteComponentProps<any>, WithTranslation {
}

interface OrderDetailsState {
}

export class OrderDetails extends Component<OrderDetailsProps, OrderDetailsState> {

    constructor(props: OrderDetailsProps) {
        super(props);

        this.state = {
        };

        this.renderSpecifics = this.renderSpecifics.bind(this)
    }

    getOne(id: string): Promise<Order> {
        return orderController.getOne(id)
    }

    update(id: string, entity: Order): Promise<Order> {
        return orderController.update(id, entity)
    }

    close(entity: Order): Promise<Order> {
        return orderController.close(entity)
    }

    delete(id: string): Promise<void> {
        return orderController.delete(id)
    }

    addBill(order: Order) {
        billController.fromOrder(order)
            .then(c => {
                this.props.history.push({
                    pathname: "bill",
                    search: "id=" + c.id,
                });
            })
    }

    addOffer(order: Order) {
        offerController.fromOrder(order)
            .then(c => {
                this.props.history.push({
                    pathname: "offer",
                    search: "id=" + c.id,
                });
            })
    }

    renderSpecifics(entity: Order, getState: () => Order, changeCallback: (entity: Order) => void): JSX.Element {
        let billContent = entity.billId === "" ?
            <AddButton id="add-Bill" onClick={() => this.addBill(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "bill",
                    search: "id=" + entity.billId,
                }}>
                {entity.billId}
            </Link>
        let offerContent = entity.offerId === "" ?
            <AddButton id="add-Order" onClick={() => this.addOffer(entity)} /> :
            <Link className="document-number"
                to={{
                    pathname: "offer",
                    search: "id=" + entity.offerId,
                }}>
                {entity.offerId}
            </Link>

        return (<>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.offer')}:</label>
                {offerContent}
            </div>
            <div className='meta-data-property'>
                <label className='meta-data-label'>{this.props.t('common.bill')}:</label>
                {billContent}
            </div>
        </>)
    }

    render() {


        return (
            <DocumentDetails
                {...this.props}
                listPrefix='orders'
                getOne={this.getOne}
                update={this.update}
                close={this.close}
                print={printOrder}
                delete={this.delete}
                renderSpecifics={this.renderSpecifics} />
        )
    }

}