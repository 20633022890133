import jwt from 'jwt-decode';
import { BillingJwtToken, Session } from './types';

/**
 * A class used to hold information about the currently logged in user
 */
 export class CurrentSession {

    sessionValidated: boolean;
    session: Session | null;
    decodedToken: BillingJwtToken | null;

    /**
     * Creates a new instance of the current user of the web client
     * 
     * @param authResponse The authentication response from the backend or null if no user has been signed in
     */
    constructor(session: Session | null) {
        if (session === null) {
            this.session = null;
            this.sessionValidated = false;
            this.decodedToken = null;
        }
        else {
            this.session = session;
            this.decodedToken = jwt(session.sessionToken);
            this.sessionValidated = true;
        }
    }

    /**
     * @returns true, if there exists a valid session token
     */
    hasValidToken(): boolean {
        if (this.sessionValidated && this.decodedToken !== null) {
            return Date.now() < this.decodedToken.exp * 1000;
        }
        else {
            return false;
        }
    }
}