import { Bill, DeliveryNote } from "../declarations/types";
import { DocumentControllerBase } from "./DocumentControllerBase";

class DeliveryNoteController extends DocumentControllerBase<DeliveryNote> {

    fromBill(entity: Bill): Promise<DeliveryNote> {
        return this.postWithToken(this.getRoute("fromBill"), entity)
    }

}

const deliveryNoteController = new DeliveryNoteController('deliveryNote');
export default deliveryNoteController;