import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Bill, defaultBillingDocument } from '../../declarations/types';
import billController from '../../functions/BillController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface BillListProps extends RouteComponentProps<any>, WithTranslation {
}

interface BillListState {
}

export class BillList extends Component<BillListProps, BillListState> {

    constructor(props: BillListProps) {
        super(props);

        this.state = {
        };
    }

    createDocument(): Promise<Bill> {
        let entity: Bill = {
            hasDueDate: false,
            dueDate: new Date(Date.now()),
            payed: false,
            orderId: "",
            offerId: "",
            cancellationId: "",
            deliveryNoteId: "",
            ...defaultBillingDocument
        }
        return billController.create(entity)
    }

    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="bill"
                createDocument={this.createDocument}
                loadDocuments={() => billController.getAll()} />
        )
    }

}