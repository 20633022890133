import React, { Component } from 'react';
import { BsPlusSquare } from "react-icons/bs";

import './Buttons.css';

interface IAddButtonProps {
    id: string,
    type: "button" | "submit" | "reset" | undefined
    onClick: () => void,
}

interface IAddButtonState {
}

export class AddButton extends Component<IAddButtonProps, IAddButtonState> {

    public static defaultProps = {
        type: "button",
    };

    render() {
        return (
            <button id={this.props.id} className="icon-button" type={this.props.type}>
                <BsPlusSquare className="icon"
                    onClick={this.props.onClick} />
            </button>
        )
    }
}