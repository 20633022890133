import React, { Component } from 'react';
import { BillingDocumentText } from '../../declarations/types';
import documentTextController from '../../functions/DocumentTextController';

import "./StandardText.css";

interface IStandardTextProps {
    data: BillingDocumentText,
}

interface IStandardTextState {
    data: BillingDocumentText
}

export class StandardText extends Component<IStandardTextProps, IStandardTextState> {

    constructor(props: IStandardTextProps) {
        super(props);

        this.state = {
            data: props.data
        };

        this.textChanged = this.textChanged.bind(this);
    }

    textChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (this.state.data.id !== null) {
            let data = this.state.data;
            data.value = e.target.value;
            this.setState({ data })
            documentTextController.update(this.state.data.id, data)
        }
    }

    render() {

        return (
            <div className='meta-data-property'>
                <label className='meta-data-label'>Text:</label>
                <textarea placeholder={"..."}
                    value={this.state.data.value} onChange={this.textChanged} />
            </div>
        )
    }
}