import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Offer, defaultBillingDocument } from '../../declarations/types';
import offerController from '../../functions/OfferController';
import { DocumentsComp } from './Base/DocumentsComp';
import { WithTranslation } from 'react-i18next';


interface OfferListProps extends RouteComponentProps<any>, WithTranslation {
}

interface OfferListState {
}

export class OfferList extends Component<OfferListProps, OfferListState> {

    constructor(props: OfferListProps) {
        super(props);

        this.state = {
        };
    }

    createDocument(): Promise<Offer> {
        let entity: Offer = {
            billId: "",
            orderId: "",
            ...defaultBillingDocument
        }
        return offerController.create(entity)
    }

    render() {
        return (
            <DocumentsComp
                {...this.props}
                detailsPrefix="offer"
                createDocument={this.createDocument}
                loadDocuments={() => offerController.getAll()} />
        )
    }

}