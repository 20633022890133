import React, { Component } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { CurrentSession } from '../../../declarations/CurrentSession';
import { Company, Session } from '../../../declarations/types';
import { Login } from '../../Login/Login';
import { WithTranslation } from 'react-i18next';
import { CompanySelection } from '../../CompanySelection/CompanySelection';

interface IProtectedRouteProps extends WithTranslation {
    path: string | undefined,
    exact: boolean,
    companies: Company[],
    currentSession: CurrentSession | null,
    currentCompany: Company | undefined,
    render: (props: RouteComponentProps<any>, translation: WithTranslation) => JSX.Element,
    authenticatedCallback: (session: Session) => void,
    companySelectedCallback: (company: Company | undefined) => void
}

interface IProtectedRouteState {
}

export class ProtectedRoute extends Component<IProtectedRouteProps, IProtectedRouteState> {

    public static defaultProps = {
        exact: false
    };

    render() {
        return (
            <Route path={this.props.path} exact={this.props.exact} render={(props) => (

                this.props.currentSession !== null && this.props.currentSession.hasValidToken()
                    ? this.props.currentCompany !== undefined
                        ? this.props.render(props, this.props)
                        : <CompanySelection {...this.props}/>
                    : <Login
                        {...props}
                        i18n={this.props.i18n}
                        t={this.props.t}
                        tReady={this.props.tReady}
                        redirect={this.props.path} authenticatedCallback={this.props.authenticatedCallback} />
            )} />
        )
    }
}